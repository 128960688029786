import React, { useContext } from "react";
import AppContext from "../../ContextProvider/AppContext";
import CmtVerticalLayout from "../../CmtLayouts/Vertical";
import CmtHeader from "../../CmtLayouts/Vertical/Header";
import Header from "../partials/Header";
import CmtSidebar from "../../CmtLayouts/Vertical/Sidebar";
import SidebarHeader from "../partials/SidebarHeader";
import SideBar from "../partials/SideBar";
import CmtContent from "../../CmtLayouts/Vertical/Content";
import { SIDEBAR_TYPE } from "../../../@theme/constants/ThemeOptions";
import clsx from "clsx";
import CmtFooter from "../../CmtLayouts/Vertical/Footer";
import Footer from "../partials/Footer";
import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import VersionService from "../../../Service/_VersionService";

const useStyles = makeStyles((theme) => ({
    SideBarBottom: {
        paddingLeft: 10,
        transition: "all .4s ease-out",
        height: 40,
        overflowY: "hidden",
        flex: "1 1",
        opacity: 1,
        ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
            height: 0,
            paddingTop: 0,
            opacity: 0,
            transition: "all 0.1s ease",
        },
    },
}));

const version = VersionService.GetVersion();

const VerticalMinimal = ({ className, showMenu, mapMode, children, account, dashboardMode }) => {
    const { drawerBreakPoint, headerType, isSidebarFixed, sidebarStyle, sidebarSize, showFooter } =
        useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();
    const currentLocation = history.location.pathname;
    const exceptionSidebarPath = ["/landingpage"];
    const isSidebarExcepted = exceptionSidebarPath.findIndex((path) => path === currentLocation) !== -1;

    return (
        <CmtVerticalLayout
            drawerBreakPoint={drawerBreakPoint}
            sidebarWidth={showMenu || isSidebarExcepted ? sidebarSize : 0}
            className={clsx(!dashboardMode ? "verticalMinimalLayout" : "", className, "bg-img")}
            style={{ height: mapMode ? "100vh" : "" }}
        >
            {showMenu
                ? [
                      <CmtHeader type={headerType} key="header" className={showMenu && !dashboardMode ? "" : "d-none"}>
                          <Header />
                      </CmtHeader>,
                      <CmtSidebar
                          type={SIDEBAR_TYPE.MINI}
                          isSidebarFixed={isSidebarFixed}
                          {...sidebarStyle}
                          key="sidebar"
                      >
                          <SidebarHeader />
                          <SideBar account={account} />
                          <Box className={classes.SideBarBottom}>
                              Version: {version.Version} Build {version.Build}
                          </Box>
                      </CmtSidebar>,
                  ]
                : null}
            ,<CmtContent className={showMenu && !mapMode && !dashboardMode ? "" : "p-0"}>{children}</CmtContent>
            {showFooter && (
                <CmtFooter type="static">
                    <Footer />
                </CmtFooter>
            )}
        </CmtVerticalLayout>
    );
};

export default VerticalMinimal;
