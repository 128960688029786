import React, { memo } from "react";
import { Button, FormControlLabel, Checkbox, Tooltip, Divider } from "@material-ui/core";
import { Info, Map, Refresh } from "@material-ui/icons";
import CmtImage from "@components/CmtImage";

const renderLayerMap = (searchLayerFilter, GetLayerPath, showLayer, setShowLayer) => {
    if (!GetLayerPath && !searchLayerFilter) return null;
    let currentGroupID = null;
    let elements = [];
    let groupElement = [];
    let groupIndex = -1;
    let renderData = searchLayerFilter ?? GetLayerPath;
    for (let i = 0; i < renderData.length; i++) {
        const obj = renderData[i];
        if (obj.LayerGroupID !== currentGroupID) {
            currentGroupID = obj.LayerGroupID;
            elements.push(
                <div key={"layergroup-" + i} className="section-header sub mt-02">
                    {obj.LayerGroupName}
                </div>
            );
        }
        elements.push(
            <div key={"layer-" + i} style={{ width: 220 }} className="d-flex align-items-center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showLayer?.[obj.LayerID] ?? false}
                            onChange={setShowLayer}
                            name={obj.LayerID + ""}
                        />
                    }
                    label={obj.LayerName}
                    className="mr-03 map-layer"
                />
                {obj.Description ? (
                    <Tooltip title={obj.Description}>
                        <Info className="text-secondary" />
                    </Tooltip>
                ) : null}
            </div>
        );
    }

    elements.forEach((element) => {
        const isGroup = element.key.search("layergroup") !== -1;
        if (isGroup) {
            groupElement.push([element]);
            groupIndex++;
        } else {
            groupElement[groupIndex].push(element);
        }
    });

    return groupElement;
};

const renderNumberLayer = (showLayer, GetLayerPath) => {
    if (!GetLayerPath) return "(0/0)";
    let renderData = GetLayerPath;
    let num = 0;
    for (const key in showLayer) {
        if (Object.hasOwnProperty.call(showLayer, key) && showLayer[key]) {
            num++;
        }
    }
    return `(${num}/${renderData.length})`;
};

const RenderLayerComponent = (props) => {
    return (
        <>
            {props.elements.map((element, index) => {
                const [layergroup, ...layer] = element;
                return (
                    <div key={index} className="map-layer-container p-10 mb-10">
                        {layergroup}
                        <div className="map-layer-list">{layer}</div>
                    </div>
                );
            })}
        </>
    );
};

const MapLayer = memo((props) => {
    const {
        mapType,
        setMapType,
        setShowLayer,
        clearShowLayer,
        masterData,
        LayerActionComponent,
        showLayer,
        searchLayerFilter,
    } = props;
    const layerComponent = renderLayerMap(searchLayerFilter, masterData?.GetLayerPath, showLayer, setShowLayer);

    return (
        <div>
            <div className="map-maptype-container">
                <div className="map-maptype-container section-header main d-flex align-items-center py-05 px-10 mb-0">
                    <Map className="mr-05" />
                    <h5>รูปแบบแผนที่</h5>
                    <Button
                        size="small"
                        variant="contained"
                        color="default"
                        className="ml-auto btn-muted"
                        onClick={() => {
                            setMapType(0);
                            clearShowLayer();
                        }}
                    >
                        <Refresh className="mr-03" />
                        ล้าง
                    </Button>
                </div>
                <div className="map-maptype-container d-flex justify-content-space-between p-05">
                    <div
                        onClick={() => setMapType(0)}
                        className={"map-maptype-wrapper " + (mapType === 0 ? "active" : "")}
                    >
                        <span className="map-maptype">
                            <CmtImage src={"/images/map/map-type-roadmap.jpg"} alt={"map-roadmap"} />
                        </span>
                        <div className="mt-4">Default</div>
                    </div>
                    <div
                        onClick={() => setMapType(1)}
                        className={"map-maptype-wrapper " + (mapType === 1 ? "active" : "")}
                    >
                        <span className="map-maptype">
                            <CmtImage src={"/images/map/map-type-satellite.jpg"} alt={"map-satellite"} />
                        </span>
                        <div className="mt-4">Satellite</div>
                    </div>
                    <div
                        onClick={() => setMapType(2)}
                        className={"map-maptype-wrapper " + (mapType === 2 ? "active" : "")}
                    >
                        <span className="map-maptype">
                            <CmtImage src={"/images/map/map-type-terrain.jpg"} alt={"map-terrain"} />
                        </span>
                        <div className="mt-4">Terrain</div>
                    </div>
                </div>
            </div>
            <Divider className="mt-10" orientation="horizontal" flexItem />
            <div className="overflowY-hidden">
                <RenderLayerComponent elements={layerComponent} />
            </div>
        </div>
    );
});

export default MapLayer;
