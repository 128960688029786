import { getLocalStorage } from "../Service/_LocalStorageService";

const initialState = {
    showMenu: window.location.pathname !== "/landingpage",
    dashboardMode: window.location.pathname === "/dashboard",
    mapMode: window.location.pathname === "/map",
    masterData: JSON.parse(getLocalStorage("masterData") || "{}"),
    account: null,
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EXAMPLE": {
            return {
                ...state,
            };
        }
        case "SHOWMENU": {
            return {
                ...state,
                showMenu: true,
            };
        }
        case "HIDEMENU": {
            return {
                ...state,
                showMenu: false,
            };
        }
        case "OPENDASHBOARD": {
            return {
                ...state,
                dashboardMode: true,
            };
        }
        case "CLOSEDASHBOARD": {
            return {
                ...state,
                dashboardMode: false,
            };
        }
        case "OPENMAPMODE": {
            return {
                ...state,
                mapMode: true,
            };
        }
        case "CLOSEMAPMODE": {
            return {
                ...state,
                mapMode: false,
            };
        }
        case "SHOWLOADING": {
            return {
                ...state,
                showLoading: true,
            };
        }
        case "HIDELOADING": {
            return {
                ...state,
                showLoading: false,
            };
        }
        case "LOGIN": {
            return {
                ...state,
                isAuth: true,
                account: action.payload,
                sessionToken: action.payload.SessionToken,
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                isAuth: false,
                account: null,
                showMenu: false,
            };
        }
        case "SETMASTERDATA": {
            if (action.payload.MasterData)
                return {
                    ...state,
                    masterData: {
                        ...state.masterData,
                        ...action.payload.MasterData,
                    },
                };
            else
                return {
                    ...state,
                    masterData: {},
                };
        }
        case "SETFILTERDATA": {
            return {
                ...state,
                filterData: action.payload.FilterData
                    ? {
                          ...action.payload.FilterData,
                      }
                    : null,
            };
        }
        default:
            return state;
    }
};

export default mainReducer;
