import Axios from "./_BaseService";

const RelationService = {
    uploadExcel: () => {
        const UPLOADEXCEL = `
            query UploadExcel {
                UploadExcel
            }
        `;
        return Axios.post("/service", {
            query: UPLOADEXCEL,
            errorPolicy: "all",
        });
    },
    getRelation: (filters) => {
        const GETRELATION = `
            query GetRelation(
                $filters: [JSON]
            ) {
                GetRelation(Filters: $filters)
            }
        `;

        return Axios.post("/service", {
            query: GETRELATION,
            variables: { filters },
            errorPolicy: "all",
        });
    },
    getQuestion: () => {
        const GETQUESTION = `
            query GetQuestion {
                GetQuestion
            }
        `;

        return Axios.post("/service", {
            query: GETQUESTION,
            errorPolicy: "all",
        });
    },
    getTopic: () => {
        const GETTOPIC = `
        query GetTopic {
            GetTopic
        }
    `;

        return Axios.post("/service", {
            query: GETTOPIC,
            errorPolicy: "all",
        });
    },
    deleteQuestion: (data) => {
        const DELETEQUESTION = `
        mutation DeleteQuestion(
            $questions: [Int],
            $options: [Int],
        ) {
            DeleteQuestion(
                Questions: $questions,
                Options: $options,
            )
        }
    `;

        return Axios.post("/service", {
            query: DELETEQUESTION,
            variables: data,
            errorPolicy: "all",
        });
    },
    addQuestion: (data) => {
        const ADDQUESTION = `
        mutation AddQuestion(
            $Question: String,
            $Code: String,
            $Input: Int,
            $Topic: Int,
            $Options: [JSON],
            $SubQuestions: [JSON],
            $GeneralInfo: Boolean
        ) {
            AddQuestion(
                Question: $Question,
                Code: $Code,
                Input: $Input,
                Topic: $Topic,
                Options: $Options,
                SubQuestions: $SubQuestions,
                GeneralInfo: $GeneralInfo
            )
        }
    `;

        return Axios.post("/service", {
            query: ADDQUESTION,
            variables: data,
            errorPolicy: "all",
        });
    },
    editQuestion: (data) => {
        const EDITQUESTION = `
        mutation EditQuestion(
            $QuestionID: Int,
            $Question: String,
            $Code: String,
            $Input: Int,
            $Topic: Int,
            $Options: [JSON],
            $SubQuestions: [JSON],
            $GeneralInfo: Boolean
        ) {
            EditQuestion(
                QuestionID: $QuestionID,
                Question: $Question,
                Code: $Code,
                Input: $Input,
                Topic: $Topic,
                Options: $Options,
                SubQuestions: $SubQuestions,
                GeneralInfo: $GeneralInfo
            )
        }
    `;

        return Axios.post("/service", {
            query: EDITQUESTION,
            variables: data,
            errorPolicy: "all",
        });
    },
    getAnalyst: (id, filters) => {
        const GETANALYSTINFO = `
            query GetAnalystInfo($Input: Int, $Filters: [JSON]) {
                GetAnalystInfo(Input: $Input, Filters: $Filters)
            }
        `;

        return Axios.post("/service", {
            query: GETANALYSTINFO,
            variables: {
                Input: id,
                Filters: filters,
            },
            errorPolicy: "all",
        });
    },
    getGeneralInfo: () => {
        const GETGENERALINFO = `
        query GetGeneralInfo {
            GetGeneralInfo
        }
    `;

        return Axios.post("/service", {
            query: GETGENERALINFO,
            errorPolicy: "all",
        });
    },
    getQuestionByID: (id) => {
        const GETQUESTIONBYID = `
        query GetQuestionByID($QuestionID: Int) {
            GetQuestionByID(QuestionID: $QuestionID)
        }
    `;

        return Axios.post("/service", {
            query: GETQUESTIONBYID,
            variables: {
                QuestionID: id,
            },
            errorPolicy: "all",
        });
    },
};

export default RelationService;
