import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import Grow from "@material-ui/core/Grow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textfield: {
        width: "100%",
    },
    iconButton: {
        padding: 6,
    },
    option: {
        width: "60%",
    },
    del: {
        color: "#e04d5b !important",
    },
}));

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const OptionForm = ({ onChange, classes, state, index, onDelete, defaultVal }) => {
    return (
        <>
            <div className="mb-05">
                <label htmlFor={`option${index}`}>
                    <b>{`ตัวเลือก${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.option}
                        variant="outlined"
                        name={`option${index}`}
                        required
                        placeholder="Enter Option"
                        onChange={(e) => onChange(e.target, index - 1, false)}
                        value={state.Options[index - 1]?.options}
                        defaultValue={defaultVal?.options}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                    <Tooltip title="ลบตัวเลือก" placement="top">
                        <IconButton
                            className={clsx(classes.iconButton, classes.del, "ml-03")}
                            size="small"
                            onClick={() => onDelete(index)}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="mb-05">
                <label htmlFor={`scoreoption${index}`}>
                    <b>{`คะแนนตัวเลือก${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.option}
                        variant="outlined"
                        name={`scoreoption${index}`}
                        placeholder="Enter Score"
                        onChange={(e) => onChange(e.target, index - 1, false)}
                        value={state.Options[index - 1]?.score}
                        defaultValue={defaultVal?.score}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const SubOptionForm = ({ onChange, classes, state, index, parentIndex, onDelete, defaultVal }) => {
    return (
        <>
            <div className="mb-05">
                <label htmlFor={`subquestionopt${index}`}>
                    <b>{`ตัวเลือก${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.option}
                        variant="outlined"
                        name={`subquestionopt${index}`}
                        required
                        placeholder="Enter Option"
                        onChange={(e) => onChange(e.target, parentIndex - 1, true, index - 1)}
                        value={state.SubQuestions[parentIndex - 1]?.Options[index]?.options}
                        defaultValue={defaultVal?.options}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                    <Tooltip title="ลบตัวเลือก" placement="top">
                        <IconButton
                            className={clsx(classes.iconButton, classes.del, "ml-03")}
                            size="small"
                            onClick={() => onDelete(parentIndex, index)}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="mb-05">
                <label htmlFor={`scoresubquestionopt${index}`}>
                    <b>{`คะแนนตัวเลือก${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.option}
                        variant="outlined"
                        name={`scoresubquestionopt${index}`}
                        placeholder="Enter Score"
                        onChange={(e) => onChange(e.target, parentIndex - 1, true, index - 1)}
                        value={state.SubQuestions[parentIndex - 1]?.Options[index]?.score}
                        defaultValue={defaultVal?.score}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const SubQuestionForm = ({ onChange, classes, state, index, onAdd, onDelete, defaultVal }) => {
    return (
        <>
            <div className="mb-05">
                <label htmlFor={`subquestion${index}`}>
                    <b>{`คำถามย่อย${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.textfield}
                        variant="outlined"
                        placeholder="Enter Subquestion"
                        name={`subquestion${index}`}
                        required
                        onChange={(e) => onChange(e.target, index - 1, false)}
                        value={state.SubQuestions[index - 1]?.Question}
                        defaultValue={defaultVal?.Question}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                    <Tooltip title="เพิ่มตัวเลือก" placement="top">
                        <IconButton
                            className={clsx(classes.iconButton, "ml-03")}
                            color="primary"
                            size="small"
                            onClick={() => onAdd(index)}
                        >
                            <AddCommentIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="ลบคำถามย่อย" placement="top">
                        <IconButton
                            className={clsx(classes.iconButton, classes.del, "ml-01")}
                            size="small"
                            onClick={() => onDelete(index)}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="mb-05">
                <label htmlFor={`subquestioncode${index}`}>
                    <b>{`โค้ด${index}`}</b>
                </label>
                <div className="d-flex">
                    <TextField
                        className={classes.option}
                        variant="outlined"
                        name={`subquestioncode${index}`}
                        required
                        placeholder="Enter Subquestion Code"
                        onChange={(e) => onChange(e.target, index - 1, false)}
                        value={state.SubQuestions[index - 1]?.Code}
                        defaultValue={defaultVal?.Code}
                        inputProps={{
                            style: {
                                padding: "10px 14px",
                                fontSize: 14,
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default function QuestionModal({ open, data, handleClose, handleAdd, inputList = [], topicList = [] }) {
    let timer = null;
    const classes = useStyles();
    const initState = {
        QuestionID: null,
        Question: "",
        Code: "",
        Options: [],
        // Options: ["น้อยที่สุด", "น้อย", "ปานกลาง", "มาก", "มากที่สุด"],
        SubQuestions: [],
        Topic: 1,
        Input: 1,
        GeneralInfo: false,
        optionForm: [],
        subQuestionsForm: [],
        optionCounter: 1,
        subCounter: 1,
    };
    const [state, setState] = useState(initState);

    useEffect(() => {
        let isEmpty = Object.keys(data).length === 0;

        if (isEmpty) {
            setState(initState);
        } else {
            let {
                Code,
                Options,
                Question,
                QuestionID,
                GeneralInfo,
                SubQuestions,
                InputID: Input,
                TopicID: Topic,
            } = data;
            setState((prevState) => ({
                ...prevState,
                GeneralInfo: Boolean(GeneralInfo),
                Code,
                Options,
                Question,
                QuestionID,
                SubQuestions,
                Input,
                Topic,
                subCounter: SubQuestions.length + 1,
                optionCounter: Options.length + 1,
                optionForm: Options.map((o, index) => (
                    <OptionForm
                        key={index + 1}
                        index={index + 1}
                        onChange={handleChange}
                        onDelete={deleteOption}
                        defaultVal={{ ...o }}
                        state={prevState}
                        classes={classes}
                    />
                )),
                subQuestionsForm: SubQuestions.map((s, index) => ({
                    form: (
                        <SubQuestionForm
                            key={index + 1}
                            index={index + 1}
                            onChange={handleChange}
                            onDelete={deleteSubQuestion}
                            onAdd={addSubOption}
                            state={prevState}
                            classes={classes}
                            defaultVal={{ ...s }}
                        />
                    ),
                    options: s.Options.map((o, oIndex) => (
                        <SubOptionForm
                            key={oIndex + 1}
                            index={oIndex + 1}
                            parentIndex={index + 1}
                            onChange={handleChange}
                            onDelete={deleteSubOptions}
                            state={prevState}
                            defaultVal={{ ...o }}
                            classes={classes}
                        />
                    )),
                    optionCounter: s.Options.length + 1,
                })),
            }));
        }
    }, [data]);

    const handleChange = ({ name, value }, index, subOption, subIndex) => {
        if (name.includes("option")) {
            if (name.includes("score")) {
                setState((getState) => ({
                    ...getState,
                    Options: getState.Options.map((row, optionIndex) => {
                        if (optionIndex === index) return { ...row, score: value };
                        return row;
                    }),
                }));
            } else {
                setState((getState) => ({
                    ...getState,
                    Options: getState.Options.map((row, optionIndex) => {
                        if (optionIndex === index) return { ...row, options: value };
                        return row;
                    }),
                }));
            }
        } else if (name.includes("subquestion")) {
            if (subOption) {
                if (name.includes("score")) {
                    setState((getState) => ({
                        ...getState,
                        SubQuestions: getState.SubQuestions.map((row, optionIndex) => {
                            if (optionIndex === index)
                                return {
                                    ...row,
                                    Options: row.Options.map((innerRow, subOptionIndex) => {
                                        if (subIndex === subOptionIndex) return { ...innerRow, score: value };
                                        return innerRow;
                                    }),
                                };
                            return row;
                        }),
                    }));
                } else {
                    setState((getState) => ({
                        ...getState,
                        SubQuestions: getState.SubQuestions.map((row, optionIndex) => {
                            if (optionIndex === index)
                                return {
                                    ...row,
                                    Options: row.Options.map((innerRow, subOptionIndex) => {
                                        if (subIndex === subOptionIndex) return { ...innerRow, options: value };
                                        return innerRow;
                                    }),
                                };
                            return row;
                        }),
                    }));
                }
            } else {
                if (name.includes("code")) {
                    setState((getState) => ({
                        ...getState,
                        SubQuestions: getState.SubQuestions.map((row, optionIndex) => {
                            if (optionIndex === index) return { ...row, Code: value };
                            return row;
                        }),
                    }));
                } else {
                    setState((getState) => ({
                        ...getState,
                        SubQuestions: getState.SubQuestions.map((row, optionIndex) => {
                            if (optionIndex === index) return { ...row, Question: value };
                            return row;
                        }),
                    }));
                }
            }
        } else {
            setState((getState) => ({ ...getState, [capitalizeFirstLetter(name)]: value }));
        }
    };

    const addOption = () => {
        setState((getState) => ({
            ...getState,
            Options: [...getState.Options, { optionID: null, options: "", score: 0 }],
            optionForm: [
                ...getState.optionForm,
                <OptionForm
                    key={getState.optionCounter}
                    index={getState.optionCounter}
                    onChange={handleChange}
                    onDelete={deleteOption}
                    state={getState}
                    classes={classes}
                />,
            ],
            optionCounter: getState.optionCounter + 1,
        }));
    };

    const addSubQuestion = () => {
        setState((getState) => ({
            ...getState,
            SubQuestions: [
                ...getState.SubQuestions,
                {
                    QuestionID: null,
                    Question: "",
                    Code: "",
                    Options: [],
                },
            ],
            subQuestionsForm: [
                ...getState.subQuestionsForm,
                {
                    form: (
                        <SubQuestionForm
                            key={getState.subCounter}
                            index={getState.subCounter}
                            onChange={handleChange}
                            onDelete={deleteSubQuestion}
                            onAdd={addSubOption}
                            state={getState}
                            classes={classes}
                        />
                    ),
                    options: [],
                    optionCounter: 1,
                },
            ],
            subCounter: getState.subCounter + 1,
        }));
    };

    const addSubOption = (index) => {
        setState((getState) => ({
            ...getState,
            SubQuestions: getState.SubQuestions.map((row, subIndex) => {
                if (subIndex === index - 1)
                    return { ...row, Options: [...row.Options, { optionID: null, options: "", score: 0 }] };
                return row;
            }),
            subQuestionsForm: getState.subQuestionsForm.map((row) => {
                if (index === row.form?.props?.index) {
                    return {
                        ...row,
                        options: [
                            ...row.options,
                            <SubOptionForm
                                key={row.optionCounter}
                                index={row.optionCounter}
                                parentIndex={index}
                                onChange={handleChange}
                                onDelete={deleteSubOptions}
                                state={getState}
                                classes={classes}
                            />,
                        ],
                        optionCounter: row.optionCounter + 1,
                    };
                }
                return row;
            }),
        }));
    };

    const deleteOption = (index) => {
        setState((getState) => ({
            ...getState,
            Options: getState.Options.map((row, optionIndex) => {
                if (optionIndex === index - 1) return null;
                return row;
            }),
            optionForm: getState.optionForm.filter((row) => row.props.index !== index),
        }));
    };

    const deleteSubQuestion = (index) => {
        setState((getState) => ({
            ...getState,
            SubQuestions: getState.SubQuestions.map((row, subIndex) => {
                if (subIndex === index - 1) return null;
                return row;
            }),
            subQuestionsForm: getState.subQuestionsForm.filter((row) => row.form.props.index !== index),
        }));
    };

    const deleteSubOptions = (parentIndex, index) => {
        setState((getState) => ({
            ...getState,
            SubQuestions: getState.SubQuestions.map((row, subIndex) => {
                if (subIndex === parentIndex - 1)
                    return {
                        ...row,
                        Options: row?.Options?.map((innerRow, optionIndex) => {
                            if (optionIndex === index - 1) return null;
                            return innerRow;
                        }),
                    };
                return row;
            }),
            subQuestionsForm: getState.subQuestionsForm.map((row) => {
                if (row.form.props.index === parentIndex)
                    return { ...row, options: row.options?.filter((innerRow) => innerRow.props.index !== index) };
                return row;
            }),
        }));
    };

    const onCloses = () => {
        timer = null;
        handleClose();
        timer = setTimeout(() => setState(initState), 250);
    };

    const setGeneralInfo = ({ target }, value) => {
        setState((getState) => ({ ...getState, [capitalizeFirstLetter(target.name)]: value }));
    };

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={onCloses}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Grow in={open}>
                    <Paper className="p-20 question-modal" style={{ width: "50vw" }}>
                        <form>
                            <div className="container">
                                <div className="mb-03">
                                    <label htmlFor="question">
                                        <b>คำถาม</b>
                                    </label>
                                    <div className="d-flex">
                                        <TextField
                                            className={classes.textfield}
                                            variant="outlined"
                                            name="question"
                                            required
                                            placeholder="Enter Question"
                                            onChange={(e) => handleChange(e.target)}
                                            value={state.Question}
                                            inputProps={{
                                                style: {
                                                    padding: "14px 14px",
                                                    fontSize: 14,
                                                },
                                            }}
                                        />
                                        <Tooltip title="เพิ่มตัวเลือก" placement="top">
                                            <IconButton
                                                className={clsx(classes.iconButton, "ml-03")}
                                                color="primary"
                                                size="small"
                                                onClick={() => addOption()}
                                            >
                                                <AddCommentIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="เพิ่มคำถามย่อย" placement="top">
                                            <IconButton
                                                className={clsx(classes.iconButton, "ml-01")}
                                                color="primary"
                                                size="small"
                                                onClick={() => addSubQuestion()}
                                            >
                                                <ContactSupportIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="mb-03">
                                    <label htmlFor="question">
                                        <b>โค้ด</b>
                                    </label>
                                    <div className="d-flex">
                                        <TextField
                                            className={classes.option}
                                            variant="outlined"
                                            name="code"
                                            required
                                            placeholder="Enter Code"
                                            onChange={(e) => handleChange(e.target)}
                                            value={state.Code}
                                            inputProps={{
                                                style: {
                                                    padding: "10px 14px",
                                                    fontSize: 14,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="pl-10">{state.optionForm}</div>
                                {state.subQuestionsForm.map((row, index) => {
                                    return (
                                        <Fragment key={"subqus" + index}>
                                            {row.form}
                                            {row.options?.length > 0 && <div className="pl-10">{row.options}</div>}
                                        </Fragment>
                                    );
                                })}
                                <div className="mb-05">
                                    <label htmlFor="topic">
                                        <b>แบบสอบถาม</b>
                                    </label>
                                    <div>
                                        <Select
                                            name="topic"
                                            variant="outlined"
                                            className="width-50"
                                            required
                                            value={state.Topic}
                                            onChange={(e) => handleChange(e.target)}
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                },
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {topicList.map((row, index) => (
                                                <MenuItem key={`topic${index}`} value={row.TopicID}>
                                                    {row.TopicName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="mb-03">
                                    <label htmlFor="input">
                                        <b>หัวข้อที่เกี่ยวข้อง</b>
                                    </label>
                                    <div>
                                        <Select
                                            name="input"
                                            variant="outlined"
                                            className="width-50"
                                            required
                                            value={state.Input}
                                            onChange={(e) => handleChange(e.target)}
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                },
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {inputList.map((row, index) => (
                                                <MenuItem key={"inputList" + index} value={index + 1}>
                                                    {row}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div className="mb-03">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="generalInfo"
                                                checked={state.GeneralInfo}
                                                onChange={setGeneralInfo}
                                                color="primary"
                                                inputProps={{ "aria-label": "generalInfo checkbox" }}
                                            />
                                        }
                                        label="ข้อมูลทั่วไป"
                                    />
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    style={{ width: 74 }}
                                    onClick={onCloses}
                                    className="mt-05"
                                    variant="contained"
                                    type="button"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    style={{ width: 74 }}
                                    onClick={(e) => handleAdd(e, state, onCloses)}
                                    className="ml-05 mt-05"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {`${Object.keys(data).length !== 0 ? "แก้ไข" : "เพิ่ม"}`}
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </Grow>
            </Modal>
        </div>
    );
}
