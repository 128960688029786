const months = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
const days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];

const utilities = {
    toCommas: (value) => {
        if (!value) return value;
        return Number(value).toLocaleString('en', { maximumFractionDigits: 6 });
    },
    checkEmail: (text) => {
        const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return reg.test(text);
    },
    toPrecision: (num, decimal = 2) => {
        if (typeof num !== 'number') return null;
        var numDecimal = Math.pow(10, decimal);
        return Math.round(num * numDecimal) / numDecimal;
    },
    toDateTimeThai: (dateTime) => {
        if (!dateTime) return "";
        let data = dateTime.split(" ");
        let date = data[0].split("-");
        return date[0] + " " + months[parseInt(date[1])] + " " + date[2] + " " + data[1];
    },
    getDefaultBudgetYear: () => {
        return 2566;
        let date = new Date();
        if (date.getMonth() < 10) return date.getFullYear() + 543;
        else return date.getFullYear() + 544;
    },
    genBudgetYearOptions: (Year) => {
        let year = Year ? Year : new Date().getFullYear() - 8 + 543;
        year = 2560;
        let arr = new Array(21);
        for (let i = 0; i < 21; ++i) arr[i] = year + i;
        return arr;
    },
    genMonthOptions: () => {
        return [
            { MonthID: 1, MonthName: months[1] },
            { MonthID: 2, MonthName: months[2] },
            { MonthID: 3, MonthName: months[3] },
            { MonthID: 4, MonthName: months[4] },
            { MonthID: 5, MonthName: months[5] },
            { MonthID: 6, MonthName: months[6] },
            { MonthID: 7, MonthName: months[7] },
            { MonthID: 8, MonthName: months[8] },
            { MonthID: 9, MonthName: months[9] },
            { MonthID: 10, MonthName: months[10] },
            { MonthID: 11, MonthName: months[11] },
            { MonthID: 12, MonthName: months[12] },
        ]
    },
    getMonthThai: (month) => {
        return months[month];
    },
    getDayThai: (day) => {
        return days[day];
    },
}

export default utilities;