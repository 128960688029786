import Axios from "./_BaseService";
let timeout = 1000 * 60 * 2;

const GISService = {
    getLayerPath: () => {
        const GETLAYERPATH = `
            query{       
                GetLayerPath{
                    LayerID
                    LayerGroupID
                    LayerGroupName
                    LayerName
                    ServerPath
                    LayerPath
                    Description
                }
            }
        `;

        return Axios.post("/service", {
            query: GETLAYERPATH,
            errorPolicy: "all",
        });
    },

    getGISAreaInfoByLatLng: (lat, lng) => {
        lat = lat || lat === 0 ? parseFloat(lat) : undefined;
        lng = lng || lng === 0 ? parseFloat(lng) : undefined;
        const GETAREAINFOBYLATLNG = `
        query GetGISAreaInfoByLatLng (
            $Lat: Float!,
            $Lng: Float!
        ) {
            GetGISAreaInfoByLatLng (
                Lat: $Lat,
                Lng: $Lng
            ) {
                ShapeName,
                ShapeID,
                ShapeCode,
                ShapeType,
                LayerID
            }
        }
        `;

        return Axios.post(
            "/service",
            {
                query: GETAREAINFOBYLATLNG,
                variables: {
                    Lat: lat,
                    Lng: lng,
                },
                errorPolicy: "all",
            },
            {
                timeout: timeout,
            }
        );
    },

    getSpatialData: (lat, lng, layerPath = []) => {
        lat = lat || lat === 0 ? parseFloat(lat) : undefined;
        lng = lng || lng === 0 ? parseFloat(lng) : undefined;
        const GETSPATIALDATA = `
        query GetSpatialData (
            $Lat: Float!,
            $Lng: Float!,
            $Layer: [String]
        ) {
            GetSpatialData (
                Lat: $Lat,
                Lng: $Lng,
                Layer: $Layer
            ) {
                ShapeName,
                ShapeID,
                ShapeCode,
                ShapeType,
                LayerID
            }
        }
        `;

        return Axios.post(
            "/service",
            {
                query: GETSPATIALDATA,
                variables: {
                    Lat: lat,
                    Lng: lng,
                    Layer: layerPath,
                },
                errorPolicy: "all",
            },
            {
                timeout: timeout,
            }
        );
    },

    getClickInfo: () => {
        const GETCLICKINFO = `
        query GetClickInfo {
            GetClickInfo {
                Name
                Message
            }
        }
        `;

        return Axios.post("/service", {
            query: GETCLICKINFO,
            errorPolicy: "all",
        });
    },
};

export default GISService;
