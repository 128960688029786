const version = {
    Version: "1",
    Build: "2021102301",
    BuildData: "2021102301",
};

const VersionService = {
    GetVersion: () => version,
}

export default VersionService;