import axios from 'axios';
import store from '../Redux';
import Swal from 'sweetalert2';

const baseURL = ((process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin);
let AlertTimeout = null;

const Axios = axios.create({
    baseURL: baseURL
});

Axios.defaults.timeout = 1000 * 30;

Axios.interceptors.request.use((request) => {
    // console.log(store.getState().sessionToken);
    request.headers.Authorization = store.getState().sessionToken;
    return request;
});

Axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (err) => {
        if (err.response) {
            if (err.response.status === 404) {
                return Promise.reject(err);
            }
        }
        else if (err.message === 'Network Error') {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                if (Swal.isVisible()) {
                    Swal.showLoading();
                    Swal.update({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    Swal.hideLoading();
                }
                else
                    Swal.fire({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    })
            }, 500);
        }
        else {
            // Swal.fire({
            //     type: 'error',
            //     title: 'เกิดข้อผิดพลาด',
            //     text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
            // })
        }
        return Promise.reject(err);
    }
);

export default Axios;