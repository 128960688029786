import version from "./_VersionService";

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const setLocalStorage = (key, data) => {
    if (data && typeof data === "object") {
        data.Version = version;
        data = JSON.stringify(data);
    }
    localStorage.setItem(key, data);
};

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const removeUserToken = () => {
    removeLocalStorage("SessionToken");
    removeLocalStorage("showImportantMessage");
};
