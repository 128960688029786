import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import LandingPage from "App/LandingPage/LandingPage";
import Home from "App/Home/Home";
// import Example from "App/Example/Example";
import Dashboard from "App/Dashboard/Dashboard";
import Map from "App/Map/Map";
import RelationChart from "App/RelationChart/RelationChart";
import QuestionnaireManagement from "App/QuestionnaireManagement/QuestionnaireManagement";
import ReleasedVersions from "App/ReleasedVersions/ReleasedVersions";
import { useSelector } from "react-redux";

const Routes = () => {
    const { isAuth, account } = useSelector(({ isAuth, account }) => ({ isAuth, account }));
    return (
        <Suspense
            fallback={
                <div style={{ height: "100vh" }}>
                    <Box className={"circularProgressRoot"}>
                        <CircularProgress />
                    </Box>
                </div>
            }
        >
            <Switch>
                <Route path="/landingpage" exact component={LandingPage} />
                {isAuth ? (
                    <Route path="/" exact render={() => <Redirect to="/home" />} />
                ) : (
                    <Redirect path="/" to="/landingpage" />
                )}
                <Route path="/home" exact component={Home} />
                <Route path="/map" exact component={Map} />
                <Route path="/relation" exact component={RelationChart} />
                <Route path="/settings/release" exact component={ReleasedVersions} />
                <Route path="/settings/mn-question" exact component={QuestionnaireManagement} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
