import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    disabled: {
        backgroundColor: "#eeeeee",
    },
    root: {
        "& input:valid:focus + fieldset": {
            borderColor: "#353755",
            borderWidth: 2,
        },
    },
}));

const TextFieldMemo = (props) => {
    const classes = useStyles();
    return (
        <TextField
            {...props}
            InputProps={{
                classes: {
                    disabled: classes.disabled,
                    root: classes.root,
                },
            }}
        />
    );
};

export default React.memo(
    TextFieldMemo,
    (prevProps, nextProps) =>
        prevProps.value === nextProps.value &&
        prevProps.error === nextProps.error &&
        prevProps.disabled === nextProps.disabled
);
