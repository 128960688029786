import React from "react";
import { Grid, Grow, Box, Typography } from "@material-ui/core";
import PageContainer from "@components/PageComponents/layouts/PageContainer";
import CmtCardHeader from "@components/CmtCard/CmtCardHeader";
import CmtCard from "@components/CmtCard";
import CmtImage from "@components/CmtImage";

class Home extends React.Component {
    onClickMenu = (location) => {
        this.props.history.push(`/${location}`);
    };

    render() {
        return (
            <PageContainer>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"menu-" + 1}>
                        <Grow in={true} timeout={400} className="height-100">
                            <div>
                                <CmtCard className="hover-zoom height-100" onClick={() => this.onClickMenu("map")}>
                                    <CmtCardHeader justifyContent="left">
                                        <CmtImage
                                            src="/images/menu/map.png"
                                            alt="map"
                                            style={{ maxWidth: "80px", marginRight: "15px" }}
                                        />
                                        <Box className="text-primary">
                                            <Typography variant="h4">ระบบแผนที่</Typography>
                                            <small className="mt-03">ตรวจสอบแผนงาน และข้อมูลเชิงภูมิศาสตร์</small>
                                        </Box>
                                    </CmtCardHeader>
                                </CmtCard>
                            </div>
                        </Grow>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"menu-" + 2}>
                        <Grow in={true} timeout={400} className="height-100">
                            <div>
                                <CmtCard
                                    className="hover-zoom height-100"
                                    onClick={() => this.onClickMenu("relation")}
                                >
                                    <CmtCardHeader justifyContent="left">
                                        <CmtImage
                                            src="/images/menu/relation.png"
                                            alt="relation"
                                            style={{ maxWidth: "80px", marginRight: "15px" }}
                                        />
                                        <Box className="text-primary">
                                            <Typography variant="h4">ทัศนคติทางสังคม</Typography>
                                            <small className="mt-03">วิเคราะห์ประมวลผลและแสดงความสัมพันธ์ของแบบสำรวจและการประเมินผล</small>
                                        </Box>
                                    </CmtCardHeader>
                                </CmtCard>
                            </div>
                        </Grow>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"menu-" + 3}>
                        <Grow in={true} timeout={400} className="height-100">
                            <div>
                                <CmtCard
                                    className="hover-zoom height-100"
                                    onClick={() => this.onClickMenu("settings/mn-question")}
                                >
                                    <CmtCardHeader justifyContent="left">
                                        <CmtImage
                                            src="/images/menu/question.png"
                                            alt="question"
                                            style={{ maxWidth: "80px", marginRight: "15px" }}
                                        />
                                        <Box className="text-primary">
                                            <Typography variant="h4">จัดการแบบสำรวจ</Typography>
                                            <small className="mt-03">เพิ่ม ลบ แก้ไขและแสดงชุดคำถามในแบบสอบถาม</small>
                                        </Box>
                                    </CmtCardHeader>
                                </CmtCard>
                            </div>
                        </Grow>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3} key={"menu-" + 4}>
                        <Grow in={true} timeout={400} className="height-100">
                            <div>
                                <CmtCard
                                    className="hover-zoom height-100"
                                    onClick={() => this.onClickMenu("settings/release")}
                                >
                                    <CmtCardHeader justifyContent="left">
                                        <CmtImage
                                            src="/images/menu/release.png"
                                            alt="release"
                                            style={{ maxWidth: "80px", marginRight: "15px" }}
                                        />
                                        <Box className="text-primary">
                                            <Typography variant="h4">Release Version</Typography>
                                            {/* <small className="mt-03">วิเคราะห์ประมวลผลในภาพรวมแบบสรุป</small> */}
                                        </Box>
                                    </CmtCardHeader>
                                </CmtCard>
                            </div>
                        </Grow>
                    </Grid>
                </Grid>
            </PageContainer>
        );
    }
}

export default Home;
