// external module
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { Box, Grid, Tooltip, Chip, Typography, CircularProgress } from "@material-ui/core";
import { Info } from "@material-ui/icons";

// internal module
import GISService from "Service/GISService";
import CoreService from "Service/CoreService";

// const ServerPath = "https://ssl.klickerlab.com/geoserver/twp/";
const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:9000" : window.location.origin;

let layerPath = [];
let layers = {};
let currentLatLng = {};
let map = null;
let maps = null;
let mapMouseDowntimeout = null;
let areaInfoObject = null;
let areaInfoData = null;
let areaClickObject = null;
let startHold = null;
let gLat = 0;
let gLng = 0;
let loadSpatial = null;
let convertTH = {
    province: "จังหวัด",
    district: "อำเภอ",
    subdistrict: "ตำบล",
    basin: "ลุ่มน้ำหลัก",
    subbasin: "ลุ่มน้ำย่อย",
};

const useAction = (props) => {
    const { setOpenMapMode, setCloseMapMode, mapMode } = props;
    const [state, setState] = useState({
        masterLoaded: props.masterData.GetLayerPath ? true : false,
        areaClick: null,
        areaMarker: null,
        areaInfoWindow: null,
        searchLayer: null,
        searchLayerFilter: null,
        showOption: false,
        spatialTab: null,
        tabIndex: 0,
        mapType: 0,
        spatialData: [],
        showLayer: {},
    });

    // |-------------------- SET STATE --------------------|
    const setMap = (getMap) => {
        map = getMap;
        maps = window.google.maps;
        toggleMapLayer(4, true);
        setState((getState) => ({
            ...getState,
            showLayer: { ...state.Layer, 4: true },
        }));
        layerPath = [
            ...layerPath,
            props.masterData?.GetLayerPath?.find((row) => row.LayerID === 4).LayerPath?.slice(5),
        ];
        map.addListener("mousedown", (e) => {
            startHold = new Date().getTime();
            clearTimeout(mapMouseDowntimeout);
            mapMouseDowntimeout = setTimeout(() => {
                if (startHold !== null) handleClickHoldMap(e, 9);
            }, 1000);
        });
        map.addListener("dragstart", (e) => {
            startHold = null;
            clearTimeout(mapMouseDowntimeout);
        });
        map.addListener("click", (e) => {
            let end = new Date().getTime();
            let longpress = end - startHold > 1000;
            startHold = null;
            clearTimeout(mapMouseDowntimeout);
            if (!longpress) {
                handleClickMap(e);
            }
        });
    };

    const setTab = (event, newValue) => {
        setState((getState) => ({ ...getState, tabIndex: newValue }));
    };

    const setMasterData = async () => {
        props.showLoading();

        try {
            const res = await GISService.getLayerPath();
            let data = {};
            data = {
                ...data,
                ...res.data,
            };

            props.setMaster(data);
            props.hideLoading();
            setState((getState) => ({ ...getState, masterLoaded: true }));
        } catch (e) {
            console.log(e);
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                icon: "error",
            });
            return;
        }
    };

    const setMapType = (index) => {
        switch (index) {
            case 0: {
                map.setMapTypeId("roadmap");
                break;
            }
            case 1: {
                map.setMapTypeId("satellite");
                break;
            }
            case 2: {
                map.setMapTypeId("terrain");
                break;
            }
            default:
                return;
        }
        setState((getState) => ({ ...getState, mapType: index }));
    };

    const setShowOption = () => {
        setState((getState) => ({ ...getState, showOption: !getState.showOption }));
    };

    const setShowLayer = (e) => {
        setState((getState) => ({
            ...getState,
            showLayer: {
                ...getState.showLayer,
                [e.target.name]: e.target.checked,
            },
        }));
        toggleMapLayer(e.target.name, e.target.checked);
    };

    // |-------------------- MANAGE MAP --------------------|
    const handleClickMap = async (e) => {
        removeAreaMarker();
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        let latLng = new maps.LatLng(lat, lng);

        setState((getState) => ({
            ...getState,
            areaClick: (
                <InfoWindow
                    position={latLng}
                    onLoad={(window) => {
                        areaClickObject = window;
                    }}
                    onUnmount={() => {
                        setState((getState) => ({ ...getState, areaClick: null }));
                    }}
                    onCloseClick={removeAreaClick}
                    options={{
                        pixelOffset: new maps.Size(0, -5),
                    }}
                >
                    <div style={{ minWidth: "200px", maxWidth: "240px" }} className="px-02 pb-10 pt-03">
                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                            <Box display="flex" alignItems="center">
                                <Info color="primary" className="mr-05" />
                                <Typography variant="h4">ตำแหน่งพื้นที่</Typography>
                            </Box>
                            <div style={{ width: "100%" }} />
                        </Box>
                        <Grid container className="section-border p-05 mt-05">
                            <Grid item xs={12} className="d-flex mb-05">
                                <Chip
                                    color="primary"
                                    className="width-100"
                                    label={<Typography variant="h5">{"lat " + lat}</Typography>}
                                />
                            </Grid>
                            <Grid item xs={12} className="d-flex">
                                <Chip
                                    color="primary"
                                    className="width-100"
                                    label={<Typography variant="h5">{"lng " + lng}</Typography>}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </InfoWindow>
            ),
        }));
    };

    const removeAreaClick = () => {
        areaClickObject = null;
        setState((getState) => ({ ...getState, areaClick: null }));
    };

    const handleClickHoldMap = async (e, zoom) => {
        removeAreaClick();

        areaInfoData = await getAreaInfoByLatLng(e.latLng.lat(), e.latLng.lng());
        setAreaMarker(e.latLng.lat(), e.latLng.lng(), zoom);
    };

    const getAreaInfoByLatLng = async (lat, lng) => {
        setState((getState) => ({ ...getState, spatialTab: null }));
        props.showLoading();
        try {
            const res = await GISService.getGISAreaInfoByLatLng(lat, lng);
            if (!res?.data?.GetGISAreaInfoByLatLng || res?.errors) {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                    icon: "error",
                });
                return;
            }
            let data = res.data.GetGISAreaInfoByLatLng;
            let newState = {
                Province: {},
                District: {},
                Subdistrict: {},
                Basin: {},
                Subbasin: {},
                AreaBase: {},
            };
            if (!data.length) {
                Swal.fire({
                    title: "ไม่พบข้อมูลพื้นที่",
                    text: "ไม่พบข้อมูลในระบบ GIS",
                    icon: "warning",
                });
                return null;
            }
            for (let i = 0; i < data.length; i++) {
                const obj = data[i];
                switch (obj.ShapeType) {
                    case "province": {
                        let data = props.masterData.GetAllProvince?.find((x) => x.ProvinceCode === obj.ShapeCode);
                        let newData = {
                            // ProvinceID: data?.ProvinceID ?? null,
                            // ProvinceName: data?.ProvinceName ?? null,
                            ProvinceID: obj.ShapeID,
                            ProvinceName: obj.ShapeName,
                        };
                        newState.Province = newData;
                        break;
                    }
                    case "district": {
                        let data = newState.Province?.ProvinceID
                            ? props.masterData?.GetDistrictObject?.[newState.Province?.ProvinceID] ??
                              props.masterData?.GetAllDistrict
                            : props.masterData?.GetAllDistrict;
                        data = data?.find((x) => x.DistrictCode === obj.ShapeCode);
                        let newData = {
                            // DistrictID: data?.DistrictID ?? null,
                            // DistrictName: data?.DistrictName ?? null,
                            DistrictID: obj.ShapeID,
                            DistrictName: obj.ShapeName,
                        };
                        newState.District = newData;
                        break;
                    }
                    case "subdistrict": {
                        let data = newState.District?.DistrictID
                            ? props.masterData?.GetSubdistrictObject?.[newState.District?.DistrictID] ??
                              props.masterData?.GetAllSubdistrict
                            : props.masterData?.GetAllSubdistrict;
                        data = data?.find((x) => x.SubdistrictCode === obj.ShapeCode);
                        let newData = {
                            // SubdistrictID: data?.SubdistrictID ?? null,
                            // SubdistrictName: data?.SubdistrictName ?? null,
                            SubdistrictID: obj.ShapeID,
                            SubdistrictName: obj.ShapeName,
                        };
                        newState.Subdistrict = newData;
                        break;
                    }
                    case "basin": {
                        let data = props.masterData.GetAllBasin?.find((x) => x.BasinCode === obj.ShapeCode);
                        let newData = {
                            // BasinID: data?.BasinID ?? null,
                            // BasinName: data?.BasinName ?? null,
                            BasinID: obj.ShapeID,
                            BasinName: obj.ShapeName,
                        };
                        newState.Basin = newData;
                        break;
                    }
                    case "subbasin": {
                        let data = props.masterData.GetAllSubbasin?.find((x) => x.SubbasinCode === obj.ShapeCode);
                        let newData = {
                            // SubbasinID: data?.SubbasinID ?? null,
                            // SubbasinName: data?.SubbasinName ?? null,
                            SubbasinID: obj.ShapeID,
                            SubbasinName: obj.ShapeName,
                        };
                        newState.Subbasin = newData;
                        break;
                    }
                    case "areabase": {
                        let data = props.masterData.GetAreaBase?.find((x) => x.AreaBaseCode === obj.ShapeCode);
                        let newData = {
                            // AreaBaseID: data?.AreaBaseID ?? null,
                            // AreaBaseName: data?.AreaBaseName ?? null,
                            // AreaBaseCode: data?.AreaBaseCode ?? null,
                            // LayerID: data ? obj.LayerID ?? null : null,
                            AreaBaseID: obj.ShapeID ?? null,
                            AreaBaseName: obj.ShapeName ?? null,
                            AreaBaseCode: obj.ShapeCode ?? null,
                            LayerID: obj.LayerID ?? null,
                        };
                        newState.AreaBase = newData;
                        break;
                    }
                    default:
                        break;
                }
            }

            return newState;
        } catch (e) {
            console.log(e);
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                icon: "error",
            });
        } finally {
            props.hideLoading();
        }
        return null;
    };

    const setAreaMarker = (lat, lng, zoom) => {
        gLat = lat;
        gLng = lng;
        if (state.areaMarker) {
            setState((getState) => ({ ...getState, areaMarker: null }));
            if ((lat || lat === 0) && (lng || lng === 0)) {
                let latLng = new maps.LatLng(lat, lng);
                setState((getState) => ({
                    ...getState,
                    areaInfoWindow: null,
                    areaMarker: (
                        <Marker
                            position={latLng}
                            onClick={openAreaInfoWindow}
                            onDragEnd={(e) => handleClickHoldMap(e, map.getZoom())}
                            draggable={true}
                        />
                    ),
                }));
                map.panTo(latLng);
                map.setZoom(zoom ?? 9);
                setMarkerInfoWindow(latLng);
            }
        } else {
            if ((lat || lat === 0) && (lng || lng === 0)) {
                let latLng = new maps.LatLng(lat, lng);
                setState((getState) => ({
                    ...getState,
                    areaInfoWindow: null,
                    areaMarker: (
                        <Marker
                            position={latLng}
                            onClick={openAreaInfoWindow}
                            onDragEnd={(e) => handleClickHoldMap(e, map.getZoom())}
                            draggable={true}
                        />
                    ),
                }));
                map.panTo(latLng);
                map.setZoom(zoom ?? 9);
                setMarkerInfoWindow(latLng);
            }
        }
    };

    const openAreaInfoWindow = () => {
        if (areaInfoObject) areaInfoObject.open(map);
    };

    const setSpatialTab = async (locations) => {
        setState((getState) => ({ ...getState, spatialTab: locations }));

        if (locations === "loading") {
            clearTimeout(loadSpatial);
            try {
                const res = await GISService.getSpatialData(gLat, gLng, layerPath);

                if (!res || res.errors) {
                    setState((getState) => ({
                        ...getState,
                        spatialTab: null,
                    }));
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: res.errors?.[0]?.Message ?? "เกิดข้อผิดพลาดระหว่างการดึงข้อมูล",
                        icon: "warning",
                    });
                    return;
                }

                loadSpatial = setTimeout(() => {
                    setState((getState) => ({
                        ...getState,
                        spatialTab: "spatial",
                        spatialData: [...res.data?.GetSpatialData],
                    }));
                }, 650);
            } catch (e) {
                console.log(e);
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                    icon: "error",
                });
                setState((getState) => ({ ...getState, spatialTab: null }));
                return;
            }
        }
    };

    const setMarkerInfoWindow = (latLng) => {
        // if (!state.areaMarker) return;
        if (!areaInfoData) {
            setState((getState) => ({ ...getState, areaInfoWindow: null }));
            return;
        }

        setState((getState) => ({
            ...getState,
            areaInfoWindow: (conditions, spatialData = []) => {
                switch (conditions) {
                    case "loading":
                        return (
                            <InfoWindow
                                position={latLng}
                                onLoad={(window) => {
                                    areaInfoObject = window;
                                }}
                                onUnmount={() => {
                                    setState((getState) => ({ ...getState, areaInfoWindow: null, spatialTab: null }));
                                }}
                                options={{
                                    pixelOffset: new maps.Size(0, -45),
                                }}
                            >
                                <>
                                    <div
                                        style={{
                                            minWidth: 225,
                                            maxWidth: 300,
                                            minHeight: 340,
                                            position: "relative",
                                            overflowY: "hidden !important",
                                        }}
                                        className="px-02 pb-10 pt-03"
                                    >
                                        <CircularProgress
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginLeft: "-20px",
                                                marginTop: "-20px",
                                            }}
                                        />
                                    </div>
                                </>
                            </InfoWindow>
                        );
                    case "detail":
                        return (
                            <InfoWindow
                                position={latLng}
                                onLoad={(window) => {
                                    areaInfoObject = window;
                                }}
                                onUnmount={() => {
                                    setState((getState) => ({ ...getState, areaInfoWindow: null, spatialTab: null }));
                                }}
                                options={{
                                    pixelOffset: new maps.Size(0, -45),
                                }}
                            >
                                <>
                                    <div
                                        style={{ minWidth: "200px", maxWidth: "225px", maxHeight: "none !important" }}
                                        className="px-02 pb-10 pt-03"
                                    >
                                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                                            <Box display="flex" alignItems="center">
                                                <Info color="primary" className="mr-05" />
                                                <Typography variant="h4">ข้อมูลเชิงพื้นที่</Typography>
                                            </Box>
                                            <div style={{ width: "100%" }} />
                                        </Box>
                                        <Box className="my-05">
                                            <Chip
                                                color="default"
                                                clickable
                                                onClick={() => setSpatialTab("spatial")}
                                                className="width-100 btn-info"
                                                label={<Typography variant="h5">Spatial Data</Typography>}
                                            />
                                        </Box>
                                        <Grid container className="section-border p-05 mt-05">
                                            {areaInfoData.Province?.ProvinceName ? (
                                                <Grid item xs={12} className="d-flex mb-05">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"จ." + areaInfoData.Province.ProvinceName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.District?.DistrictName ? (
                                                <Grid item xs={12} className="d-flex mb-05">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"อ." + areaInfoData.District.DistrictName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.Subdistrict?.SubdistrictName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"ต." + areaInfoData.Subdistrict.SubdistrictName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Grid className="mt-10 section-border p-05">
                                            {areaInfoData.Basin?.BasinName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="default"
                                                        className={`width-100 ${
                                                            areaInfoData.AreaBase?.AreaBaseName ? "mb-05" : " "
                                                        }`}
                                                        label={
                                                            <Typography variant="h5">
                                                                {areaInfoData.Basin.BasinName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.AreaBase?.AreaBaseName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="default"
                                                        className="width-100"
                                                        label={
                                                            <Tooltip title={areaInfoData.AreaBase.AreaBaseName}>
                                                                <Typography variant="h5">
                                                                    {areaInfoData.AreaBase?.AreaBaseCode
                                                                        ? areaInfoData.AreaBase.AreaBaseCode
                                                                        : ""}
                                                                </Typography>
                                                            </Tooltip>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Box className="mt-10">
                                            <Chip
                                                color="default"
                                                clickable
                                                onClick={removeAreaMarker}
                                                className="width-100 btn-danger"
                                                label={<Typography variant="h5">ลบหมุด</Typography>}
                                            />
                                        </Box>
                                    </div>
                                </>
                            </InfoWindow>
                        );
                    case "spatial":
                        return (
                            <InfoWindow
                                position={latLng}
                                onLoad={(window) => {
                                    areaInfoObject = window;
                                }}
                                onUnmount={() => {
                                    setState((getState) => ({ ...getState, areaInfoWindow: null, spatialTab: null }));
                                }}
                                options={{
                                    pixelOffset: new maps.Size(0, -45),
                                }}
                            >
                                <div style={{ minWidth: "200px", maxWidth: "260px" }} className="px-02 pb-10 pt-03">
                                    <Box display="flex" justifyContent="center" flexWrap="wrap">
                                        <Box display="flex" alignItems="center">
                                            <Info color="primary" className="mr-05" />
                                            <Typography variant="h4">ข้อมูลทางภูมิศาสตร์</Typography>
                                        </Box>
                                        <div style={{ width: "100%" }} />
                                    </Box>
                                    <Box className="my-05">
                                        <Chip
                                            color="default"
                                            clickable
                                            onClick={() => setSpatialTab("detail")}
                                            className="width-100 btn-info"
                                            label={<Typography variant="h5">Back</Typography>}
                                        />
                                    </Box>
                                    <Grid container className="section-border p-05 mt-05">
                                        {spatialData.map((row, index) => {
                                            return (
                                                <>
                                                    <Grid item xs={4} className="d-flex mb-05">
                                                        <h4>{convertTH[row.ShapeType]}</h4>
                                                    </Grid>
                                                    <Grid item xs={8} className="d-flex mb-05">
                                                        <h4 className="text-normal">{row.ShapeName}</h4>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            </InfoWindow>
                        );
                    default:
                        return (
                            <InfoWindow
                                position={latLng}
                                onLoad={(window) => {
                                    areaInfoObject = window;
                                    window.close();
                                }}
                                onUnmount={() => {
                                    setState((getState) => ({ ...getState, areaInfoWindow: null, spatialTab: null }));
                                }}
                                options={{
                                    pixelOffset: new maps.Size(0, -45),
                                }}
                            >
                                <>
                                    <div style={{ minWidth: "200px", maxWidth: "225px" }} className="px-02 pb-10 pt-03">
                                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                                            <Box display="flex" alignItems="center">
                                                <Info color="primary" className="mr-05" />
                                                <Typography variant="h4">ข้อมูลเชิงพื้นที่</Typography>
                                            </Box>
                                            <div style={{ width: "100%" }} />
                                        </Box>
                                        <Box className="my-05">
                                            <Chip
                                                color="default"
                                                clickable
                                                onClick={() => setSpatialTab("loading")}
                                                className="width-100 btn-info"
                                                label={<Typography variant="h5">Spatial Data</Typography>}
                                            />
                                        </Box>
                                        <Grid container className="section-border p-05 mt-05">
                                            {areaInfoData.Province?.ProvinceName ? (
                                                <Grid item xs={12} className="d-flex mb-05">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"จ." + areaInfoData.Province.ProvinceName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.District?.DistrictName ? (
                                                <Grid item xs={12} className="d-flex mb-05">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"อ." + areaInfoData.District.DistrictName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.Subdistrict?.SubdistrictName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="primary"
                                                        className="width-100"
                                                        label={
                                                            <Typography variant="h5">
                                                                {"ต." + areaInfoData.Subdistrict.SubdistrictName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Grid className="mt-10 section-border p-05">
                                            {areaInfoData.Basin?.BasinName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="default"
                                                        className={`width-100 ${
                                                            areaInfoData.AreaBase?.AreaBaseName ? "mb-05" : " "
                                                        }`}
                                                        label={
                                                            <Typography variant="h5">
                                                                {areaInfoData.Basin.BasinName}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                            {areaInfoData.AreaBase?.AreaBaseName ? (
                                                <Grid item xs={12} className="d-flex">
                                                    <Chip
                                                        color="default"
                                                        className="width-100"
                                                        label={
                                                            <Tooltip title={areaInfoData.AreaBase.AreaBaseName}>
                                                                <Typography variant="h5">
                                                                    {areaInfoData.AreaBase?.AreaBaseCode
                                                                        ? areaInfoData.AreaBase.AreaBaseCode
                                                                        : ""}
                                                                </Typography>
                                                            </Tooltip>
                                                        }
                                                    />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <Box className="mt-10">
                                            <Chip
                                                color="default"
                                                clickable
                                                onClick={removeAreaMarker}
                                                className="width-100 btn-danger"
                                                label={<Typography variant="h5">ลบหมุด</Typography>}
                                            />
                                        </Box>
                                    </div>
                                </>
                            </InfoWindow>
                        );
                }
            },
        }));
    };

    const removeAreaMarker = () => {
        areaInfoData = null;
        areaInfoObject = null;
        setState((getState) => ({ ...getState, areaMarker: null, areaInfoWindow: null, spatialTab: null }));
    };

    const getLayerData = (layer) => {
        if (!props.masterData?.GetLayerPath) return null;
        return (
            props.masterData.GetLayerPath.find((x) => {
                return x.LayerID == layer;
            }) ?? null
        );
    };

    const toggleMapLayer = (layer, checked) => {
        if (checked) {
            let layerData = getLayerData(layer);
            if (!layerData) return;
            let geoObj = {
                url: "",
                layerPath: "",
                layerName: "",
                groupName: "",
                groupID: "",
                imageMap: null,
            };
            geoObj.url = layerData.ServerPath;
            geoObj.layerPath = layerData.LayerPath;
            geoObj.layerName = layerData.LayerName;
            geoObj.groupName = layerData.LayerGroupName;
            geoObj.groupID = layerData.LayerGroupID;
            let deltaX = 0.0;
            let deltaY = 0.0;
            let areaGEO = new maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    let proj = map.getProjection();
                    let zfactor = Math.pow(2, zoom);
                    let top = proj.fromPointToLatLng(
                        new maps.Point((coord.x * 256) / zfactor, (coord.y * 256) / zfactor)
                    );
                    let bot = proj.fromPointToLatLng(
                        new maps.Point(((coord.x + 1) * 256) / zfactor, ((coord.y + 1) * 256) / zfactor)
                    );
                    let bbox =
                        "&minx=" +
                        (top.lng() + deltaX) +
                        "&miny=" +
                        (bot.lat() + deltaY) +
                        "&maxx=" +
                        (bot.lng() + deltaX) +
                        "&maxy=" +
                        (top.lat() + deltaY);
                    let url = baseURL + "/getmap?";
                    url += "layer=" + geoObj.layerPath;
                    url += bbox;
                    url += "&token=" + props.account.SessionToken;
                    return url;
                },
                tileSize: new maps.Size(256, 256),
                opacity: 0.7,
                isPng: true,
            });
            geoObj.imageMap = areaGEO;
            layers[layer] = geoObj;
            map.overlayMapTypes.push(areaGEO);
        } else {
            if (!layers[layer]) return;
            let index = map.overlayMapTypes.indexOf(layers[layer].imageMap);
            if (index !== -1) map.overlayMapTypes.removeAt(index);
            layers[layer] = null;
        }
    };

    const clearShowLayer = (e) => {
        let newShowLayer = {};
        props.masterData.GetLayerPath.forEach((obj) => {
            newShowLayer[obj.LayerID] = false;
            toggleMapLayer(obj.LayerID, false);
        });
        setState((getState) => ({
            ...getState,
            showLayer: newShowLayer,
        }));
    };

    const checkInvisibleBadgeMenu = (data) => {
        if (!data) return true;
        let invisible = true;
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                if (data[key] && (typeof data[key] !== "object" || (typeof data[key] === "object" && data[key].length)))
                    invisible = false;
            }
        }
        return invisible;
    };

    const getNumberLayer = (showLayer, GetLayerPath) => {
        if (!GetLayerPath) return "(0/0)";
        let renderData = GetLayerPath;
        let num = 0;
        for (const key in showLayer) {
            if (Object.hasOwnProperty.call(showLayer, key) && showLayer[key]) {
                num++;
            }
        }
        return `(${num}/${renderData.length})`;
    };

    // |-------------------- CYCLES --------------------|
    useEffect(() => {
        if (!mapMode) {
            setOpenMapMode();
        }
        if (!state.masterLoaded) {
            setMasterData();
        }

        return () => {
            setCloseMapMode();
        };
    }, []);

    useEffect(() => {
        layerPath = [];
        let isEmptyLayer = Object.keys(layers).length === 0;

        if (isEmptyLayer) {
            for (const key in state.showLayer) {
                if (Object.hasOwnProperty.call(state.showLayer, key)) {
                    const element = state.showLayer[key];

                    if (element) {
                        let getLayerPath = props.masterData?.GetLayerPath?.find(
                            (row) => row.LayerID == key
                        ).LayerPath?.slice(5);
                        layerPath = [...layerPath, getLayerPath];
                    }
                }
            }
        } else {
            for (const key in layers) {
                if (Object.hasOwnProperty.call(layers, key)) {
                    const element = layers[key];
                    const formatedLayerPath = element?.layerPath.slice(5);

                    if (formatedLayerPath) layerPath.push(formatedLayerPath);
                }
            }
        }
    }, [state.showLayer, props.masterData]);

    return {
        state,
        setMap,
        setTab,
        setMapType,
        setShowLayer,
        setShowOption,
        clearShowLayer,
        checkInvisibleBadgeMenu,
        getNumberLayer,
    };
};

export default useAction;
