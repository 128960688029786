import React, { useState, useEffect, useRef } from "react";
import PageContainer from "@components/PageComponents/layouts/PageContainer";
import { Card, CardContent, Button, Grid, colors } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { openDashboard, closeDashboard } from "Redux/action";

import CmtImage from "@components/CmtImage";
import { connect } from "react-redux";
import { showMenu, hideMenu, login } from "Redux/action";
import Chart from "Util/Chart";
import ChartLine from "Util/ChartLine";

const data = [
    {
        name: "พื้นที่น้ำท่วม (ไร่)",
        data: [
            { x: 2006, y: 3799693 },
            { x: 2007, y: 1138392 },
            { x: 2008, y: 1697329 },
            { x: 2009, y: 829665 },
            { x: 2010, y: 4034538 },
            { x: 2011, y: 6214830 },
            { x: 2012, y: 1344618 },
            { x: 2013, y: 2307990 },
            { x: 2014, y: 128732 },
            { x: 2015, y: 0 },
            { x: 2016, y: 845461 },
            { x: 2017, y: 2943581 },
            { x: 2018, y: 17530 },
        ],
    },
    {
        name: "chart2",
        data: [300000, 1000000, 800000, 200000, 2000000],
        xAxis: ["cat1", "cat2", "cat3", "cat4", "cat5"],
    },
    {
        name: "chart3",
        data: [300000],
        xAxis: ["cat1"],
    },
];

const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home",
    },
    {
        label: "Dashboard",
        isActive: true,
        link: "/dashboard",
    },
];

const Dashboard = (props) => {
    const { setOpenDashboard, setCloseDashboard } = props;
    const [loaded, setLoad] = useState(false);
    const [renderChart, setChart] = useState({
        data: data[0].data,
        xAxis: data[0].xAxis,
        isFinal: false,
        history: [],
    });
    let height = 128;
    let gTimer = null;

    const handleChart = (_e, _chart) => {
        // console.log(getBar);
        gTimer = null;
        _chart.current.chart.showLoading();

        gTimer = setTimeout(() => {
            setChart((getChart) => ({
                ...getChart,
                history: [...getChart.history, "drilldown"],
                data: data[1].data,
                xAxis: data[1].xAxis,
            }));

            _chart.current.chart.hideLoading();
        }, 1000);
    };

    const handleBack = (_chart) => {
        // console.log(getBar);
        gTimer = null;
        _chart.current.chart.showLoading();

        gTimer = setTimeout(() => {
            setChart((getChart) => ({
                ...getChart,
                history: getChart.history.filter((row, index) => index !== getChart.history.length - 1),
                data: getChart.history.length > 1 ? data[1].data : data[0].data,
                xAxis: getChart.history.length > 1 ? data[1].xAxis : data[0].xAxis,
            }));

            _chart.current.chart.hideLoading();
        }, 1000);
    };

    useEffect(() => {
        setOpenDashboard();

        let _timer = setTimeout(() => {
            console.log(data[0].data.map((row) => row.y));
            setLoad(true);
            setChart((getChart) => ({ ...getChart, data: data[0].data, xAxis: data[0].xAxis }));
        }, 1400);

        return () => {
            setCloseDashboard();
            clearTimeout(_timer);
        };
    }, []);

    const zone = [
        "#fff3e0",
        "#ffe0b2",
        "#ffcc80",
        "#ffb74d",
        "#ffa726",
        "#ff9800",
        "#fb8c00",
        "#f57c00",
        "#ef6c00",
        "#e65100",
    ];

    const palette = ["#4b477a", "#5bd3c7", "#b4bec9", "#b1692f", "#002333", "#159a9c"];

    return (
        <PageContainer>
            <Card className="p-10">
                <Grid spacing={8} container>
                    <Grid item sm={12} md={6}>
                        {loaded ? (
                            <Chart
                                data={renderChart.data}
                                xAxis={renderChart.xAxis}
                                handleChart={handleChart}
                                handleBack={handleBack}
                                loading={!loaded}
                                history={renderChart.history}
                                // zone={zone}
                                color={palette}
                                yAxis={`พื้นที่น้ำท่วม (ไร่)`}
                            />
                        ) : (
                            <Skeleton height={height} />
                        )}
                    </Grid>
                    <Grid item sm={12} md={6}>
                        {loaded ? <ChartLine /> : <Skeleton height={height} />}
                    </Grid>
                </Grid>
            </Card>
        </PageContainer>
    );
};

const mapDispatchtoProps = (dispatch) => ({
    dispatchHideMenu: () => dispatch(hideMenu()),
    dispatchShowMenu: () => dispatch(showMenu()),
    setOpenDashboard: () => dispatch(openDashboard()),
    setCloseDashboard: () => dispatch(closeDashboard()),
});

export default connect(null, mapDispatchtoProps)(Dashboard);
