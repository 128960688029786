// external module
import React from "react";
import { connect } from "react-redux";
import { Box, IconButton, Grid, Badge, Tooltip, Drawer, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { Layers, Settings, ExpandLess, ExpandMore, ChevronLeft } from "@material-ui/icons";
import { showLoading, hideLoading, openMapMode, closeMapMode, setMasterData, setFilterData } from "Redux/action";

// internal modules
import GoogleMap from "Util/Maps";
import useAction from "./hooks/useAction";

import MapLayer from "./components/MapLayers";

const LayerActionComponent = ({ number }) => (
    <div className="text-center text-bold width-100">
        <Layers />
        <div>{number ?? ""}</div>
    </div>
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

const Map = (props) => {
    const {
        state,
        setMap,
        setTab,
        setMapType,
        setShowLayer,
        setShowOption,
        clearShowLayer,
        checkInvisibleBadgeMenu,
        // getNumberLayer,
    } = useAction(props);

    return (
        <>
            {!state.masterLoaded || !props.mapMode ? null : (
                <div className="map-wrapper-map">
                    <div className="width-100 height-100">
                        <GoogleMap setMap={setMap} apiKeys={props.account?.APIKeys?.GoogleMaps}>
                            {state.areaMarker}
                            {state.areaInfoWindow ? state.areaInfoWindow(state.spatialTab, state.spatialData) : null}
                            {state.areaClick}
                        </GoogleMap>
                    </div>
                    <div className="map-speeddial-filter">
                        <Grid
                            container
                            className={`map-menu ${state.showOption ? "map-menu-motion" : ""}`}
                            direction="column"
                            spacing={4}
                            style={{ width: "auto" }}
                        >
                            <Grid>
                                <div>
                                    <Tooltip
                                        title="ตั้งค่าแผนที่"
                                        placement="right"
                                        enterDelay={500}
                                        enterNextDelay={500}
                                    >
                                        <Badge
                                            color="error"
                                            invisible={checkInvisibleBadgeMenu(state.showLayer)}
                                            variant="dot"
                                        >
                                            <IconButton onClick={() => setShowOption()} className={`icon-btn-default`}>
                                                {/* {state.showOption ? <ExpandMore /> : <ExpandLess />} */}
                                                <ChevronLeft
                                                    className={`${state.showOption ? "map-btn" : "map-btn-back"}`}
                                                />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                        <Drawer variant="persistent" anchor="right" open={state.showOption} onClose={setShowOption}>
                            <Paper className="d-flex" square>
                                <Tabs
                                    orientation="vertical"
                                    value={state.tabIndex}
                                    onChange={setTab}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="icon tabs example"
                                >
                                    <Tab className="map-tab" icon={<Layers />} {...a11yProps(0)} />
                                    {/* <Tab className="map-tab" icon={<Settings />} {...a11yProps(1)} /> */}
                                </Tabs>
                                <TabPanel value={state.tabIndex} index={0}>
                                    <MapLayer
                                        setMapType={setMapType}
                                        mapType={state.mapType}
                                        setShowOption={setShowOption}
                                        setShowLayer={setShowLayer}
                                        clearShowLayer={clearShowLayer}
                                        masterData={props.masterData}
                                        LayerActionComponent={LayerActionComponent}
                                        showLayer={state.showLayer}
                                        searchLayer={state.searchLayer}
                                        searchLayerFilter={state.searchLayerFilter}
                                    />
                                </TabPanel>
                                {/* <TabPanel value={state.tabIndex} index={1}>
                                    <div style={{ minWidth: 240 }}>Test</div>
                                </TabPanel> */}
                            </Paper>
                        </Drawer>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStatetoProps = (state) => ({
    masterData: state.masterData,
    mapMode: state.mapMode,
    filterData: state.filterData,
    account: state.account,
});

const mapDispatchtoProps = (dispatch) => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    setOpenMapMode: () => dispatch(openMapMode()),
    setCloseMapMode: () => dispatch(closeMapMode()),
    setMaster: (data) => dispatch(setMasterData(data)),
    setFilterData: (data) => dispatch(setFilterData(data)),
});

export default connect(mapStatetoProps, mapDispatchtoProps)(Map);
