import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker, OverlayView } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: 13.673811,
    lng: 101.069368,
};

function MapComponent(props) {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        language: "th",
        googleMapsApiKey: process.env.NODE_ENV === "development" ? undefined : props.apiKeys,
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(map) {
        setMap(map);
        map.setZoom(7);
        if (props.setMap) props.setMap(map);
    }, []);

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            options={{
                streetViewControl: false,
                scaleControl: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_LEFT,
                },
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_TOP,
                },
                styles: [
                    {
                        featureType: "all",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            }}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {props.children}
        </GoogleMap>
    ) : (
        <></>
    );
}

export default React.memo(MapComponent);
