import React, { useEffect } from "react";
import AppWrapper from "../@components/AppWrapper";
import Routes from "Routes";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import CoreService from "Service/CoreService";
import { setLocalStorage, getLocalStorage, removeUserToken } from "../Service/_LocalStorageService";
import { login, logout } from "Redux/action";

function App() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        let SessionToken = getLocalStorage("SessionToken");
        if (SessionToken) {
            dispatch(login({ SessionToken: SessionToken }));
            CoreService.getAccountToken(SessionToken)
                .then((res) => {
                    let data = res?.data?.GetAccountByToken;
                    if (!data || res.errors) {
                        removeUserToken();
                        dispatch(logout());
                        CoreService.Logout();
                        return;
                    }
                    setLocalStorage("SessionToken", data.SessionToken);
                    dispatch(login(data));
                })
                .catch((e) => {
                    console.log(e);
                    removeUserToken();
                    dispatch(logout());
                    CoreService.Logout();
                });
        } else {
            if (!history.location.pathname.includes("/resetpassword")) history.push("/landingpage");
        }
    }, []);

    return <AppWrapper Children={Routes} />;
}

export default App;
