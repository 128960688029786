import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../Redux";
import AppContextProvider from "@components/ContextProvider";
import Wrapper from "./Wrapper";

function App() {
    return (
        <AppContextProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <Wrapper />
                </BrowserRouter>
            </Provider>
        </AppContextProvider>
    );
}

export default App;
