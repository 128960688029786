import React, { useState } from "react";
import Swal from "sweetalert2";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import RelationService from "Service/RelationService";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    iconButton: {
        padding: 6,
    },
    del: {
        color: "#e04d5b !important",
    },
});

function Row(props) {
    const { row, handleDelete, openEdit } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    const isExpand = row.Options?.length > 0 || row.SubQuestions?.length > 0;

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell style={{ paddingBottom: 9, paddingTop: 9 }}>
                    {isExpand ? (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ) : null}
                </TableCell>
                <TableCell style={{ paddingBottom: 9, paddingTop: 9 }} component="th" scope="row">
                    {row.Question}
                </TableCell>
                <TableCell style={{ paddingBottom: 9, paddingTop: 9 }} align="right">
                    {row.Topic}
                </TableCell>
                <TableCell style={{ paddingBottom: 9, paddingTop: 9 }} align="right">
                    {row.InputID}
                </TableCell>
                <TableCell style={{ minWidth: 120, paddingBottom: 9, paddingTop: 9 }} align="right">
                    <Tooltip title="เพิ่มตัวเลือก" placement="top">
                        <IconButton className={clsx(classes.iconButton, "ml-03")} onClick={() => openEdit(row.QuestionID)} color="primary" size="small">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="ลบคำถามย่อย" placement="top">
                        <IconButton
                            className={clsx(classes.iconButton, classes.del, "ml-01")}
                            onClick={() => handleDelete(row)}
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="px-30 mb-10">
                            {row.Options?.length > 0 ? (
                                <div className="px-05 mb-03">
                                    <h4 className="mb-03">ตัวเลือก</h4>
                                    <div className="px-10">
                                        <ul>
                                            {row.Options?.map((options) => (
                                                <li key={"option" + options.OptionID}>{options.OptionName}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                            {row.SubQuestions?.length > 0 ? (
                                <div className="px-05">
                                    <h4 className="mb-03">คำถามย่อย</h4>
                                    <div className="px-10">
                                        <ul>
                                            {row.SubQuestions?.map((question, questionIndex) => (
                                                <li key={"subquestions" + questionIndex}>
                                                    {question.Question}
                                                    {question.Options?.length > 0 ? (
                                                        <div className="px-05">
                                                            <h4 className="mb-03">ตัวเลือก</h4>
                                                            <div className="px-10">
                                                                <ul>
                                                                    {question.Options?.map((options) => (
                                                                        <li key={"option" + options.OptionID}>
                                                                            {options.OptionName}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function QuestionTable(props) {
    const { data, openEdit } = props;
    const [state, setState] = useState({
        page: 0,
        rowsPerPage: 5,
        openModal: false,
        deleteRow: null,
    });

    const handleChangePage = (event, newPage) => {
        setState((getState) => ({ ...getState, page: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        setState((getState) => ({ ...getState, page: 0, rowsPerPage: parseInt(event.target.value, 10) }));
    };

    const handleDelete = (row) => {
        const { QuestionID, Options, SubQuestions } = row;
        let payload = { questions: [QuestionID], options: [] };

        if (Options.length > 0) {
            Options.forEach((o) => payload.options.push(o.OptionID));
        }

        if (SubQuestions.length > 0) {
            SubQuestions.forEach((s) => {
                payload.questions.push(s.QuestionID);
                if (s.Options.length > 0) {
                    s.Options.forEach((o) => payload.options.push(o.OptionID));
                }
            });
        }

        RelationService.deleteQuestion(payload)
            .then((res) => {
                if (res?.error || !res?.data?.DeleteQuestion) {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                        icon: "error",
                    });
                    return;
                } else {
                    alert("success!");
                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                handleClose();
            });
    };

    const handleOpen = (data) => {
        setState((getState) => ({ ...getState, openModal: true, deleteRow: data }));
    };

    const handleClose = () => {
        setState((getState) => ({ ...getState, openModal: false }));

        let timer = setTimeout(() => {
            setState((getState) => ({ ...getState, deleteRow: null }));
        }, 350);
    };

    return (
        <Paper>
            <Dialog open={state.openModal} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        {`คุณต้องการลบคำถาม${
                            state.deleteRow?.Question ? ` "${state.deleteRow.Question}" ` : "นี้"
                        }หรือไม่?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        ยกเลิก
                    </Button>
                    <Button onClick={() => handleDelete(state.deleteRow)} color="primary" autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className="th" style={{ width: 62.5 }} />
                            <TableCell className="th text-primary" style={{ fontWeight: 600 }}>
                                คำถาม
                            </TableCell>
                            <TableCell className="th text-primary" style={{ fontWeight: 600 }} align="right">
                                แบบสอบถาม
                            </TableCell>
                            <TableCell className="th text-primary" style={{ fontWeight: 600 }} align="right">
                                หัวข้อที่เกี่ยวข้อง
                            </TableCell>
                            <TableCell className="th text-primary" style={{ fontWeight: 600 }} align="right">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                            .map((row, index) => (
                                <Row key={"data" + index} row={row} handleDelete={handleOpen} openEdit={openEdit} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
