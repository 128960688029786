import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Utilities from "Util/Utilities";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#ffffff",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const OverAll = ({ data, count }) => {
    // let x = data.result ? 34 - (data.result % 1 !== 0) * 12 : 37;

    return (
        <>
            <HtmlTooltip
                placement="bottom-start"
                title={
                    <div className="p-05">
                        {data.header?.subTitle.map((s) => {
                            return (
                                <>
                                    <Typography>{s.question}</Typography>
                                    <ul className="mb-05">
                                        {s.value.map((v) => (
                                            <li className="ml-10">{v}</li>
                                        ))}
                                    </ul>
                                </>
                            );
                        })}
                    </div>
                }
            >
                <div
                    className={`overall d-flex ${data.header.subTitle?.length > 0 ? "" : "cancel-tooltip"}`}
                    style={{ alignItems: "center" }}
                >
                    <Typography variant="h4" component="h2">
                        {`${data.header.title} :`}
                    </Typography>
                    <Typography
                        className={"ml-10 overall-value overall-value-" + data.grade}
                        variant="h1"
                        component="h1"
                    >
                        {data.result}
                    </Typography>
                    <Typography className="ml-10" variant="h3" component="h1">
                        {` / 5`}
                    </Typography>
                </div>
            </HtmlTooltip>
            <div
                className={`mt-05 overall d-flex ${data.header.subTitle?.length > 0 ? "" : "cancel-tooltip"}`}
                style={{ alignItems: "center" }}
            >
                <Typography variant="h4" component="h2">
                    {`จำนวน :`}
                </Typography>
                <Typography className="ml-10" variant="h3" component="h1">
                    {count}
                </Typography>
                <Typography className="ml-10" variant="h4" component="h2">
                    {`ชุด`}
                </Typography>
            </div>
            <div className="d-flex mt-05">
                <div className="m-05 d-flex" style={{ alignItems: "center" }}>
                    <div className="legend-threshold-good"></div>
                    <div className="legend-label ml-10">{"Score > 75 %"}</div>
                </div>
                <div className="m-05 d-flex" style={{ alignItems: "center" }}>
                    <div className="legend-threshold-fair"></div>
                    <div className="legend-label ml-10">{"50 % < Score <= 75 %"}</div>
                </div>
                <div className="m-05 d-flex" style={{ alignItems: "center" }}>
                    <div className="legend-threshold-bad"></div>
                    <div className="legend-label ml-10">{"Score <= 50 %"}</div>
                </div>
            </div>
        </>
    );
};

export default OverAll;
