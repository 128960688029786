import { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Utilities from "./Utilities";

const ChartLine = ({ title, data, color, credits, yAxis, xAxis, handleChart, zone, unit = "ไร่" }) => {
    const charts = useRef();
    const option = {
        title: {
            // text: "สรุปข้อมูลแผนระดับ " + this.props.Level + " ทั้งหมด",
        },

        subtitle: {
            text: "",
        },

        yAxis: {
            title: {
                text: "ค่า",
            },
        },
        xAxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        tooltip: {
            enabled: true,
            formatter: function () {
                return `<b>${
                    this.series?.name ? `${this.series.name} (${this.key})` : this.key
                }</b>: ${Utilities.toCommas(this.y)} ${unit ?? ""}`;
            },
        },
        legend: {
            enabled: true,
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    defer: 500,
                },
                events: {
                    mouseOver: (e) => {
                        e.target.dataLabelsGroup.show();
                    },
                    mouseOut: (e) => {
                        e.target.dataLabelsGroup.hide();
                    },
                    legendItemClick: function (e) {
                        e.preventDefault();
                    },
                },
            },
            line: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 2,
                    },
                },
                marker: {
                    enabled: false,
                },
            },
        },
        series: [
            {
                name: "2001",
                data: [900, 1270, 2489, 4738, 6510, 8433, 10174, 14737, 15482, 18945, 21446, 24785],
            },
            {
                name: "2002",
                data: [1050, 1429, 2894, 4491, 7529, 7859, 11573, 15005, 17430, 19482, 20148, 23817],
            },
            {
                name: "2003",
                data: [734, 1130, 2583, 4882, 6401, 8130, 9024, 13582, 16492, 21942, 22104, 22900],
            },
            {
                name: "2004",
                data: [2491, 3216, 4624, 5218, 7027, 8426, 11839, 13728, 18327, 22162, 24718, 29264],
            },
            {
                name: "2005",
                data: [1263, 3074, 5025, 5291, 6024, 7531, 8812, 11424, 15236, 20152, 21520, 22162],
            },
            {
                name: "2006",
                data: [2148, 6136, 7247, 9548, 12535, 14378, 17432, 22034, 26135, 31536, 33513, 35923],
            },
            {
                name: "2007",
                data: [1870, 2271, 2683, 3968, 6823, 7190, 10483, 13286, 15387, 18465, 19083, 20381],
            },
            {
                name: "2008",
                data: [643, 1940, 3017, 4271, 6934, 8557, 12058, 13975, 15830, 17736, 19375, 20157],
            },
            {
                name: "2009",
                data: [1753, 2497, 4725, 5135, 6179, 8792, 11334, 15439, 18430, 22384, 24729, 26390],
            },
            {
                name: "2010",
                data: [1374, 2048, 3069, 3967, 6429, 8735, 10482, 14852, 16380, 17246, 18425, 19372],
            },
            {
                name: "2011",
                data: [876, 1197, 4788, 8088, 10876, 14378, 18942, 25421, 29531, 36325, 40758, 43743],
            },
            {
                name: "2012",
                data: [3422, 7430, 9537, 11491, 13693, 15923, 17250, 19352, 20148, 22514, 23862, 25128],
            },
            {
                name: "2013",
                data: [1124, 1387, 1742, 2592, 3471, 5828, 7024, 8802, 12597, 15379, 17840, 19052],
            },
            {
                name: "2014",
                data: [624, 1262, 2054, 2748, 4062, 5189, 6803, 7501, 8388, 11042, 12899, 14295],
            },
            {
                name: "2015",
                data: [582, 1370, 1890, 2501, 3218, 4589, 5723, 6061, 6560, 7213, 8027, 8720],
            },
            {
                name: "2016",
                data: [1057, 1196, 1508, 2389, 3127, 4217, 5140, 6426, 9421, 13789, 15730, 17357],
            },
            {
                name: "2017",
                data: [2068, 3950, 5159, 5937, 7019, 9673, 13094, 15987, 19540, 24863, 27014, 30175],
            },
            {
                name: "2018",
                data: [1752, 2069, 4218, 4719, 5601, 7164, 9624, 12407, 14920, 21567, 26359, 29447],
            },
            {
                name: "2019",
                data: [842, 2048, 3571, 5250, 7946, 11753, 14069, 18042, 21209, 23908, 25037, 27401],
            },
        ],
    };

    return (
        <HighchartsReact
            immutable
            ref={charts}
            highcharts={Highcharts}
            options={option}
            callback={function (chart) {
                // on complete
                chart.series.forEach((row) => row.dataLabelsGroup.hide());
            }}
        />
    );
};

export default ChartLine;
