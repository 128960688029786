export const example = () => ({
    type: "EXAMPLE",
});

export const openDashboard = () => ({
    type: "OPENDASHBOARD",
});

export const closeDashboard = () => ({
    type: "CLOSEDASHBOARD",
});

export const showMenu = () => ({
    type: "SHOWMENU",
});

export const hideMenu = () => ({
    type: "HIDEMENU",
});

export const openMapMode = () => ({
    type: "OPENMAPMODE",
});

export const closeMapMode = () => ({
    type: "CLOSEMAPMODE",
});

export const showLoading = () => ({
    type: "SHOWLOADING",
});

export const hideLoading = () => ({
    type: "HIDELOADING",
});

export const login = (data) => ({
    type: "LOGIN",
    payload: data,
});

export const logout = () => ({
    type: "LOGOUT",
});

export const setMasterData = (MasterData) => ({
    type: "SETMASTERDATA",
    payload: {
        MasterData: MasterData,
    },
});

export const setFilterData = (filter) => ({
    type: "SETFILTERDATA",
    payload: {
        FilterData: filter,
    },
});
