import React, { useContext } from "react";
import {
    createTheme,
    StylesProvider,
    ThemeProvider,
    jssPreset,
} from "@material-ui/core/styles";
import {
    Box,
    CircularProgress,
    CssBaseline
} from "@material-ui/core"
import AppContext from "../ContextProvider/AppContext";
import AppLayout from "../AppLayout";
import { connect } from 'react-redux';
import { create } from "jss";
import rtl from "jss-rtl";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Loader = connect(({ showLoading }) => ({ showLoading }))(({ showLoading }) => {
    if (showLoading)
        return (
            <Box className="circularProgressRoot">
                <CircularProgress></CircularProgress>
            </Box>
        )
    else return null;
});

const AppWrapper = ({ Children }) => {
    const { theme } = useContext(AppContext);
    return (
        <ThemeProvider theme={createTheme(theme)}>
            <StylesProvider jss={jss}>
                <CssBaseline />
                <AppLayout>
                    <Children />
                </AppLayout>
                <Loader />
            </StylesProvider>
        </ThemeProvider>
    );
};

export default AppWrapper;
