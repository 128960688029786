import Axios from "./_BaseService";

const CoreService = {
    Login: (data) => {
        data = {
            Username: data.Username ? data.Username.trim() : undefined,
            Password: data.Password ? data.Password.trim() : undefined,
        };

        const query = `
            query Login (
                $Username: String!,
                $Password: String!
            ) {
                Login (
                    Username: $Username,
                    Password: $Password
                ) {
                    AccountID,
                    RoleID,
                    RoleName,
                    OrganizationID,
                    OrganizationName,
                    Username,
                    FirstName,
                    LastName,
                    Email,
                    SessionToken,
                    FileData,
                    ResetToken,
                    Permissions,
                    APIKeys
                }
            }
        `;

        return Axios.post("/service", {
            query: query,
            variables: data,
            errorPolicy: "all",
        });
    },
    Logout: () => {
        const query = `
            {
                Logout
            }
        `;

        return Axios.post("/service", {
            query: query,
            errorPolicy: "all",
        });
    },
    getAllMasterData: () => {
        const getAllMasterData = `
            query{
                GetAllBasin {
                    BasinID
                    BasinCode
                    BasinName
                }
                GetAllSubbasin {
                    SubbasinID
                    SubbasinCode
                    SubbasinName
                }
                GetAllProvince {
                    ProvinceID
                    ProvinceCode
                    ProvinceName
                    ProvinceNameEN
                    RegionID
                    Lat
                    Lng
                }
                GetAllDistrict {
                    DistrictID
                    DistrictCode
                    DistrictName
                    DistrictNameEN
                    ProvinceID
                    Lat
                    Lng
                }
                GetAllSubdistrict {
                    SubdistrictID
                    SubdistrictCode
                    SubdistrictName
                    SubdistrictNameEN
                    DistrictID
                    Lat
                    Lng
                }
            }
        `;

        return Axios.post(
            "/service",
            {
                query: getAllMasterData,
                errorPolicy: "all",
            },
            {
                timeout: 60000,
            }
        );
    },
    getAccountToken: (token) => {
        const GETACCOUNTTOKEN = `
            query GetAccountByToken(
                $SessionToken: String!
            ) {
                GetAccountByToken(
                    SessionToken: $SessionToken
                ) {
                    AccountID,
                    RoleID,
                    RoleName,
                    OrganizationID,
                    OrganizationName,
                    Username,
                    FirstName,
                    LastName,
                    Email,
                    SessionToken,
                    FileData,
                    ResetToken,
                    Permissions,
                    APIKeys
                }
            } 
        `;

        return Axios.post("/service", {
            query: GETACCOUNTTOKEN,
            variables: {
                SessionToken: token,
            },
            errorPolicy: "all",
        });
    },
};

export default CoreService;
