import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    pageHeaderRoot: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
            flexDirection: "row"
        }
    },
    titleRoot: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: 10
        }
    }
}));

const PageHeader = ({ heading, breadcrumbComponent, children, ml, ...rest }) => {
    const classes = useStyles();
    return (
        <Box
            className={clsx(classes.pageHeaderRoot, "page-header")}
            mb={{ xs: 3, md: 4, lg: 5 }}
            {...rest}
        >
            <Box ml={ml}>
                <Typography
                    component="div"
                    variant="h1"
                    className={clsx(classes.titleRoot, "title")}
                >
                    {heading}
                </Typography>
            </Box>
            <Box ml={{ sm: "auto" }}>{breadcrumbComponent}</Box>

            {children}
        </Box>
    );
};

export default PageHeader;
