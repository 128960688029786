import { Home, Settings, Person, Description, Dashboard, Map, Apps, QuestionAnswer } from "@material-ui/icons";

const navigationMenus = [
    {
        id: "menu-1",
        name: "Home",
        icon: <Home />,
        type: "item",
        link: "/home",
        windowLocation: true,
    },
    {
        id: "menu-3",
        name: "Map",
        icon: <Map />,
        type: "item",
        link: "/map",
        windowLocation: true,
    },
    {
        id: "menu-4",
        name: "ทัศนคติทางสังคม",
        icon: <Apps />,
        type: "item",
        link: "/relation",
        windowLocation: true,
    },
    {
        id: "menu-5",
        name: "Settings",
        icon: <Settings />,
        type: "collapse",
        link: "/settings",
        children: [
            // {
            //     id: "menu-5.1",
            //     name: "User",
            //     icon: <Person />,
            //     type: "item",
            //     link: "/settings/user",
            //     permission: "Account",
            // },
            {
                id: "menu-5.2",
                name: "Questionnaire Management",
                icon: <QuestionAnswer />,
                type: "item",
                link: "/settings/mn-question",
                permission: "Account",
            },
            {
                id: "menu-5.3",
                name: "Released Versions",
                icon: <Description />,
                type: "item",
                link: "/settings/release",
                permission: "Account",
            },
        ],
    },
];

const getNavigationMenu = (account) => {
    let data = [];
    if (!account?.Permissions) return data;
    for (let i = 0; i < navigationMenus.length; i++) {
        const element = navigationMenus[i];
        if (element.children?.length) {
            let hasPermission = false;
            let newData = { ...element, children: [] };
            for (let i = 0; i < element.children.length; i++) {
                const childEl = element.children[i];
                if (account.Permissions[childEl.permission]) {
                    newData.children.push(childEl);
                    hasPermission = true;
                }
            }
            if (hasPermission) data.push(newData);
        } else if ((!element.permission && !element.children) || account.Permissions[element.permission])
            data.push(element);
    }
    return data;
};

export default {
    navigationMenus,
    getNavigationMenu,
};
