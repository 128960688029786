import React from "react";
import { Popper, Paper, Grow, Grid } from "@material-ui/core";
import Utilities from "./Utilities";

var d3 = require("d3");

// var width = 2050,
//     height = 1800,
var boxWidth = 340,
    boxHeight = 30,
    valueWidth = 40,
    valueHeight = 40,
    gap = {
        width: 150,
        height: 32,
    },
    margin = {
        top: 50,
        right: 16,
        bottom: 16,
        left: 16,
    };

var Nodes = [];
var links = [];
var lvlCount = 0;
var selected = null;
var timeout = null;
var disableInputs = [9, 10, 11, 16, 17, 18, 13, 14];

const diagonal = function link(d) {
    return (
        "M" +
        d.source.y +
        "," +
        d.source.x +
        "C" +
        (d.source.y + d.target.y) / 2 +
        "," +
        d.source.x +
        " " +
        (d.source.y + d.target.y) / 2 +
        "," +
        d.target.x +
        " " +
        d.target.y +
        "," +
        d.target.x
    );
};

const arc = function link(d) {
    return (
        "M" +
        d.source.y +
        "," +
        d.source.x +
        "C" +
        ((d.source.y + d.target.y) / 2 - 22.5) +
        "," +
        d.source.x +
        " " +
        ((d.source.y + d.target.y) / 2 - 22.5) +
        "," +
        d.target.x +
        " " +
        d.target.y +
        "," +
        d.target.x
    );
};

const find = (text) => {
    var i;
    for (i = 0; i < Nodes.length; i += 1) {
        if (Nodes[i].name === text) {
            return Nodes[i];
        }
    }
    return null;
};

const mouse_action = (val, stat, direction, select, root) => {
    let selected;

    if (select) {
        d3.select("#" + val.id).classed("selected", stat);
        d3.select("#" + val.id + "value").classed("selected", stat);
        d3.select("#" + val.id + "border").classed("selected", stat);
        selected = document.querySelector("#" + val.id);
        selected?.style.setProperty("--group-color", `#${val.color}bb`);
    } else {
        d3.select("#" + val.id).classed("active", stat);
        d3.select("#" + val.id + "value").classed("active", stat);
        d3.select("#" + val.id + "border").classed("active", stat);
        selected = document.querySelector("#" + val.id);
        selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
    }

    links.forEach(function (d) {
        if (direction === "root") {
            if (d.source.id === val.id) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    let selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }

                if (select) {
                    d3.select("#" + d.target.id).classed("selected", stat);
                    d3.select("#" + d.target.id + "value").classed("selected", stat);
                    d3.select("#" + d.target.id + "border").classed("selected", stat);
                    selected = document.querySelector("#" + d.target.id);
                    selected?.style.setProperty("--group-color", `#${d.target.color}bb`);
                } else {
                    d3.select("#" + d.target.id).classed("active", stat);
                    d3.select("#" + d.target.id + "value").classed("active", stat);
                    d3.select("#" + d.target.id + "border").classed("active", stat);
                    selected = document.querySelector("#" + d.target.id);
                    selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                }

                if (d.target.lvl < val.lvl) mouse_action(d.target, stat, "left", select);
                else if (d.target.lvl > val.lvl) mouse_action(d.target, stat, "right", select);
            }
            if (d.target.id === val.id) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    let selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }

                if (select) {
                    d3.select("#" + d.source.id).classed("selected", stat);
                    d3.select("#" + d.source.id + "value").classed("selected", stat);
                    d3.select("#" + d.source.id + "border").classed("selected", stat);
                    selected = document.querySelector("#" + d.source.id);
                    selected?.style.setProperty("--group-color", `#${d.source.color}bb`);
                } else {
                    d3.select("#" + d.source.id).classed("active", stat);
                    d3.select("#" + d.source.id + "value").classed("active", stat);
                    d3.select("#" + d.source.id + "border").classed("active", stat);
                    selected = document.querySelector("#" + d.source.id);
                    selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                }

                if (d.source.lvl < val.lvl) mouse_action(d.source, stat, "left", select, val);
                else if (d.source.lvl > val.lvl) mouse_action(d.source, stat, "right", select, val);
            }
        } else if (direction === "left") {
            if (d.source.id === val.id && d.target.lvl < val.lvl) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }
                mouse_action(d.target, stat, direction, select, val);
            }
            if (d.target.id === val.id && d.source.lvl < val.lvl) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }
                mouse_action(d.source, stat, direction, select, val);
            }
            if (
                (d.target.id === val.id || d.source.id === val.id) &&
                d.source.lvl === val.lvl &&
                d.target.lvl === val.lvl
            ) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }
                if (d.target.id === val.id) {
                    if (select) {
                        d3.select("#" + d.source.id).classed("selected", stat);
                        d3.select("#" + d.source.id + "value").classed("selected", stat);
                        d3.select("#" + d.source.id + "border").classed("selected", stat);
                        selected = document.querySelector("#" + d.source.id);
                        selected?.style.setProperty("--group-color", `#${d.source.color}bb`);
                    } else {
                        d3.select("#" + d.source.id).classed("active", stat);
                        d3.select("#" + d.source.id + "value").classed("active", stat);
                        d3.select("#" + d.source.id + "border").classed("active", stat);
                        selected = document.querySelector("#" + d.source.id);
                        selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                    }
                } else {
                    if (select) {
                        d3.select("#" + d.target.id).classed("selected", stat);
                        d3.select("#" + d.target.id + "value").classed("selected", stat);
                        d3.select("#" + d.target.id + "border").classed("selected", stat);
                        selected = document.querySelector("#" + d.target.id);
                        selected?.style.setProperty("--group-color", `#${d.target.color}bb`);
                    } else {
                        d3.select("#" + d.target.id).classed("active", stat);
                        d3.select("#" + d.target.id + "value").classed("active", stat);
                        d3.select("#" + d.target.id + "border").classed("active", stat);
                        selected = document.querySelector("#" + d.target.id);
                        selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                    }
                }
            }
        } else if (direction === "right") {
            if (d.source.id === val.id && d.target.lvl > val.lvl) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }
                mouse_action(d.target, stat, direction, select, val);
            }
            if (d.target.id === val.id && d.source.lvl > val.lvl) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }
                mouse_action(d.source, stat, direction, select, val);
            }
            if (
                (d.target.id === val.id || d.source.id === val.id) &&
                d.source.lvl === val.lvl &&
                d.target.lvl === val.lvl
            ) {
                if (select) {
                    d3.select("#" + d.id).classed("selectedlink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color", `#${root ? root.color : val.color}`);
                } else {
                    d3.select("#" + d.id).classed("activelink", stat); // change link color
                    d3.select("#" + d.id).classed("link", !stat); // change link color
                    selected = document.querySelector("#" + d.id);
                    selected?.style.setProperty("--link-color-active", `#${root ? root.color : val.color}`);
                }

                if (d.target.id === val.id) {
                    if (select) {
                        d3.select("#" + d.source.id).classed("selected", stat);
                        d3.select("#" + d.source.id + "value").classed("selected", stat);
                        d3.select("#" + d.source.id + "border").classed("selected", stat);
                        selected = document.querySelector("#" + d.source.id);
                        console.log(selected);
                        selected?.style.setProperty("--group-color", `#${d.source.color}bb`);
                    } else {
                        d3.select("#" + d.source.id).classed("active", stat);
                        d3.select("#" + d.source.id + "value").classed("active", stat);
                        d3.select("#" + d.source.id + "border").classed("active", stat);
                        selected = document.querySelector("#" + d.source.id);
                        selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                    }
                } else {
                    if (select) {
                        d3.select("#" + d.target.id).classed("selected", stat);
                        d3.select("#" + d.target.id + "value").classed("selected", stat);
                        d3.select("#" + d.target.id + "border").classed("selected", stat);
                        selected = document.querySelector("#" + d.target.id);
                        console.log(selected);
                        selected?.style.setProperty("--group-color", `#${d.target.color}bb`);
                    } else {
                        d3.select("#" + d.target.id).classed("active", stat);
                        d3.select("#" + d.target.id + "value").classed("active", stat);
                        d3.select("#" + d.target.id + "border").classed("active", stat);
                        selected = document.querySelector("#" + d.target.id);
                        selected?.style.setProperty("--group-color-hover", `#${val.color}90`);
                    }
                }
            }
        }
    });
};

const unvisite_links = () => {
    links.forEach(function (d) {
        d.visited = false;
    });
};

const renderRelationshipGraph = (data, svg, setHover, header, handleDrilldown) => {
    var count = [];
    let lvl1Count = data.Nodes.filter((n) => n.lvl === 1)?.length;
    let lvl2Count = data.Nodes.filter((n) => n.lvl === 2)?.length;

    data.Nodes.forEach(function (d) {
        count[d.lvl] = 0;
    });
    lvlCount = count.length;

    data.Nodes.forEach((d, i) => {
        if (d.member) {
            if (d.lvl === 3 && lvl1Count > lvl2Count) {
                d.y =
                    margin.top +
                    (boxHeight + gap.height) * (count[d.lvl] + 0.5 + (lvl1Count / 2 - lvl2Count / 2) + d.member / 2);
                count[d.lvl] += d.member;
            } else {
                d.y = margin.top + (boxHeight + gap.height) * (count[d.lvl] + 0.5 + d.member / 2);
                count[d.lvl] += d.member;
            }
        } else {
            if (d.lvl === 2 && lvl1Count > lvl2Count) {
                d.y = margin.top + (boxHeight + gap.height) * (count[d.lvl] + (lvl1Count / 2 - lvl2Count / 2) + 1);
                count[d.lvl] += 1;
            } else {
                d.y = margin.top + (boxHeight + gap.height) * (count[d.lvl] + 1);
                count[d.lvl] += 1;
            }
        }
        d.x = margin.left + d.lvl * (boxWidth + gap.width);
        d.id = "n" + i;
        Nodes.push(d);
    });

    data.links.forEach((d) => {
        let source = find(d.source);
        let target = find(d.target);
        if (!source || !target) return;
        links.push({
            source: source,
            target: target,
            id: "l" + source.id + target.id,
            sameLevel: d.sameLevel,
        });
    });

    unvisite_links();

    ////// Header //////

    svg.append("g").attr("class", "header");

    var head = svg
        .select(".header")
        .selectAll("g")
        .data(
            header.map((d, i) => {
                return {
                    x: margin.left + d.lvl * (boxWidth + gap.width),
                    y: margin.top,
                    title: d.title,
                    subTitle: d.subTitle,
                };
            })
        )
        .enter();

    head.append("text")
        .attr("x", (d) => d.x + boxWidth / 2 - (d.title.length * 6.8) / 2)
        .attr("y", (d) => d.y)
        .attr("width", boxWidth)
        .attr("height", boxHeight)
        .attr("font-weight", "600")
        .attr("rx", 6)
        .attr("ry", 6)
        .text((d) => d.title);

    head.append("text")
        .attr("x", (d) => d.x + boxWidth / 2 - (d.subTitle.length * 6.8) / 2)
        .attr("y", (d) => d.y + 25)
        .attr("width", boxWidth)
        .attr("height", boxHeight)
        .attr("font-weight", "600")
        .attr("fill", "#1976d2")
        .attr("rx", 6)
        .attr("ry", 6)
        .text((d) => d.subTitle);

    ////// Data //////

    svg.append("g").attr("class", "nodes");

    var node = svg
        .select(".nodes")
        .selectAll("g")
        .data(Nodes)
        .enter()
        .append("g")
        .attr("class", "unit position-relative");

    node.append("path")
        .attr(
            "class",
            (d) =>
                "node" +
                (d.Disabled ? " cancel-tooltip" : "") +
                (d.IsHeader === 0 && d.name.includes("INPUT") ? "" : " d-none")
        )
        .attr("stroke-width", "1.5px")
        .attr("stroke", "#000")
        .attr("fill", "#fff")
        .attr(
            "d",
            (d) => `M${d.x + boxWidth - 20},${d.y}
        h${20}
        q5,0 5,5
        v${boxHeight - 10}
        q0,5 -5,5
        h${-20}
        z`
        )
        .on("click", function () {
            handleDrilldown(d3.select(this).datum());
        });

    node.append("text")
        .attr("x", (d) => d.x + boxWidth - 14)
        .attr("y", (d) => d.y + 20)
        .attr("width", 25)
        .attr("height", boxHeight)
        .attr(
            "class",
            (d) =>
                (d.IsHeader === 0 && d.name.includes("INPUT") ? "svg-icon" : " d-none") +
                (d.Disabled ? " cancel-tooltip" : "")
        )
        .attr("fill", (d) => `${d.Disabled ? "#aaa" : "#555"}`)
        .attr("font-size", function (d) {
            return "70px";
        })
        .text(function (d) {
            return "\uf0f6";
        })
        .on("click", function () {
            handleDrilldown(d3.select(this).datum());
        });

    node.append("rect")
        .attr("x", (d) => (d.IsHeader === 0 && d.name.includes("INPUT") ? d.x + 7 : d.x + 5))
        .attr("y", (d) => d.y)
        .attr("id", (d) => d.id)
        .attr("width", (d) => (d.IsHeader === 0 && d.name.includes("INPUT") ? boxWidth - 28 : boxWidth))
        .attr("height", boxHeight)
        .attr("class", (d) => "node" + (d.IsHeader ? " node-header" : "") + (d.IsExternal ? " external" : ""))
        .attr("style", (d) =>
            d.IsHeader
                ? `fill: #${d.color} !important; stroke-width: 2px !important; stroke: rgb(128 128 128) !important`
                : "fill: #CCC"
        )
        .attr("stroke-width", "2.5px")
        .attr("rx", (d) => (d.IsHeader === 0 && d.name.includes("INPUT") ? 0 : 6))
        .attr("ry", 6)
        .on("mouseover", function () {
            timeout = setTimeout(() => setHover(this, d3.select(this).datum()), 500);
            mouse_action(d3.select(this).datum(), true, "root");
            unvisite_links();
        })
        .on("mouseout", function () {
            // timeout = setTimeout(() => setHover(null, ''), 1000);
            clearTimeout(timeout);
            setHover(null, "");
            mouse_action(d3.select(this).datum(), false, "root");
            unvisite_links();
        })
        .on("click", function () {
            if (!selected) selected = this;
            else if (selected === this) selected = null;
            else {
                mouse_action(d3.select(selected).datum(), false, "root", true);
                selected = this;
            }
            if (selected) {
            }
            mouse_action(d3.select(this).datum(), !this.classList.contains("selected"), "root", true);
            unvisite_links();
        });

    node.append("rect")
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y - 8)
        .attr("width", valueWidth + 8)
        .attr("height", valueHeight + 8)
        .attr("id", (d) => d.id + "border")
        .attr("class", (d) => "node-value-border" + (d.IsExternal ? " external" : ""))
        .attr("fill", "#fff")
        .attr("rx", "50%")
        .attr("ry", "50%");

    node.append("rect")
        .attr("x", (d) => d.x + 4)
        .attr("y", (d) => d.y - 4)
        .attr("id", (d) => d.id + "value")
        .attr("width", valueWidth)
        .attr("height", valueHeight)
        .attr("class", (d) => `node-value-${d.grade}` + (d.IsExternal ? " external" : ""))
        .attr("fill", (d) => (d.IsHeader ? "#0e8082" : "#CCC"))
        .attr("rx", "50%")
        .attr("ry", "50%")
        .on("mouseover", function () {
            timeout = setTimeout(() => setHover(this, d3.select(this).datum()), 500);
            mouse_action(d3.select(this).datum(), true, "root");
            unvisite_links();
        })
        .on("mouseout", function () {
            // timeout = setTimeout(() => setHover(null, ''), 1000);
            clearTimeout(timeout);
            setHover(null, "");
            mouse_action(d3.select(this).datum(), false, "root");
            unvisite_links();
        })
        .on("click", function () {
            if (!selected) selected = this;
            else if (selected === this) selected = null;
            else {
                mouse_action(d3.select(selected).datum(), false, "root", true);
                selected = this;
            }
            if (selected) {
            }
            mouse_action(d3.select(this).datum(), !this.classList.contains("selected"), "root", true);
            unvisite_links();
        });

    node.append("text")
        .attr("class", "label")
        .style("text-shadow", "3px 3px 3px #00000040")
        .attr("fill", "#fff")
        .attr("x", (d) => d.x + 54)
        .attr("y", (d) => d.y + 20)
        .text(function (d) {
            if (d.label && d.label.length > 35) {
                return d.label.substring(0, 35) + "...";
            }
            return d.label;
        });

    node.append("text")
        .attr("class", "label")
        .style("text-shadow", "3px 3px 3px #00000040")
        .attr("fill", "#fff")
        .attr("x", (d) => {
            let result = Utilities.toPrecision(d.result, 1);
            return d.x + (result ? 9 + (result < 10) * 5 + (result % 1 === 0) * 6 - (result === 100) * 4 : 20);
        })
        .attr("y", (d) => d.y + 21)
        .text((d) => {
            if (d.result || d.result === 0) return Utilities.toPrecision(d.result, 1);
            else return " -";
        });

    node.append("text")
        .attr("class", "label")
        .style("text-shadow", "2px 2px 2px #00000040")
        .style("font-size", "12px")
        .attr("fill", "#fff")
        .attr("x", (d) => d.x + 19)
        .attr("y", (d) => d.y + 30)
        .text((d) => (d.IsHeader || (!d.result && d.result !== 0) ? "" : ""));

    links.forEach(function (li) {
        svg.append("path", "g")
            .attr("class", "link")
            .attr("fill", "none")
            .attr("style", () =>
                li.source.name.includes("GROUP") || li.target.name.includes("GROUP")
                    ? `stroke: #${li.source.color} !important`
                    : ""
            )
            .attr("stroke-width", "2.5px")
            .attr("stroke", "#ccc")
            .attr("id", li.id)
            .attr("d", () => {
                if (li.sameLevel) {
                    var oTarget = {
                        x: li.target.y + 0.5 * boxHeight,
                        y: li.target.x,
                    };
                    var oSource = {
                        x: li.source.y + 0.5 * boxHeight,
                        y: li.source.x,
                    };
                    // oSource.y += boxWidth;
                    // oTarget.y += boxWidth;
                    return arc({
                        source: oSource,
                        target: oTarget,
                    });
                } else {
                    var oTarget = {
                        x: li.target.y + 0.5 * boxHeight,
                        y: li.target.x,
                    };
                    var oSource = {
                        x: li.source.y + 0.5 * boxHeight,
                        y: li.source.x + 5,
                    };
                    if (oSource.y < oTarget.y) {
                        oSource.y += boxWidth;
                    } else {
                        oTarget.y += boxWidth;
                    }
                    return diagonal({
                        source: oSource,
                        target: oTarget,
                    });
                }
            });
    });
};

class RelationChart extends React.Component {
    state = {
        hover: null,
        hoverObject: null,
    };
    svg = null;
    chartDiv = null;

    setHover = (element, obj) => {
        this.setState({ hover: element, hoverObject: obj });
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps.data !== this.props.data) {
            if (this.svg) {
                d3.selectAll(".relation svg").remove();
                Nodes = [];
                links = [];
                lvlCount = 0;
                selected = null;
                clearTimeout(timeout);
            }

            selected = null;
            let data = nextProps.data?.Nodes ?? [];
            var width = 0,
                height = 0,
                levelCount = 0,
                levelDataCount = 0,
                lastLevelDataCount = 0,
                lastLevel = null;

            data.sort((a, b) => {
                if (a.lvl < b.lvl) {
                    return -1;
                }
                if (a.lvl > b.lvl) {
                    return 1;
                }
                return 0;
            });

            for (let i = 0; i < data.length; i++) {
                if (data[i].lvl !== lastLevel) {
                    lastLevel = data[i].lvl;
                    if (levelDataCount > lastLevelDataCount) {
                        lastLevelDataCount = levelDataCount;
                    }
                    levelDataCount = 1;
                    levelCount++;
                } else {
                    levelDataCount++;
                }
                if (i + 1 === data.length) {
                    if (levelDataCount > lastLevelDataCount) {
                        lastLevelDataCount = levelDataCount;
                    }
                }
            }

            levelCount = this.props.header.length;

            width = boxWidth * levelCount + gap.width * (levelCount - 1) + margin.right + margin.left;
            height = (boxHeight + gap.height) * (lastLevelDataCount + 1) + margin.top + margin.bottom;

            this.svg = d3
                .select(this.chartDiv)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("id", "relation-chart")
                .append("g");

            renderRelationshipGraph(
                nextProps.data,
                this.svg,
                this.setHover,
                this.props.header,
                this.props.handleDrilldown
            );
            return true;
        }
        return true;
    }

    componentWillUnmount() {
        d3.selectAll(".relation svg").remove();
        Nodes = [];
        links = [];
        lvlCount = 0;
        selected = null;
        clearTimeout(timeout);
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <div ref={(el) => (this.chartDiv = el)} />
                <Popper
                    open={this.state.hover ? true : false}
                    anchorEl={this.state.hover}
                    role={undefined}
                    transition
                    disablePortal
                >
                    <Grow in={this.state.hover ? true : false}>
                        <Paper className="custom-tooltip">
                            <div>{this.state.hoverObject ? this.state.hoverObject.label ?? "" : ""}</div>
                            <div className="sub-text">
                                {this.state.hoverObject ? this.state.hoverObject.description?.remark ?? "" : ""}
                            </div>
                            {this.state.hoverObject?.description ? (
                                <div>
                                    <hr className="mb-10" />
                                    <Grid container>
                                        {this.state.hoverObject.description.list.map((x, i) => {
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    key={"descriptionList" + i}
                                                    spacing={6}
                                                    className="mb-0"
                                                >
                                                    <Grid item xs={9}>
                                                        {"• " + x.label}
                                                    </Grid>
                                                    <Grid item xs={3} className="text-right">
                                                        {x.result ?? "-"}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            ) : null}
                        </Paper>
                    </Grow>
                </Popper>
            </>
        );
    }
}

export default RelationChart;
