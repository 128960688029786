import React from "react";
import { connect } from "react-redux";
import { showMenu, hideMenu, login } from "Redux/action";
import { Card, Box, Button, Typography, Grid } from "@material-ui/core";
import TextFieldMemo from "Util/TextFieldMemo";
import Swal from "sweetalert2";
import CoreService from "Service/CoreService";
import { setLocalStorage } from "Service/_LocalStorageService";
import { NavLink } from "react-router-dom";
import CmtImage from "@components/CmtImage";

const fakeCopyRight = {
    copyRight: "© 2022 Klicker lab",
    subCopyRight: "This is klickler lab product",
};

class LandingPage extends React.Component {
    state = {
        Username: "",
        Password: "",
    };
    logoUrl = "/images/logo-temporary.png";

    componentDidMount() {
        this.props.dispatchHideMenu();
    }

    componentWillUnmount() {
        this.props.dispatchShowMenu();
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    login = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.Username) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                text: "กรุณากรอก Username",
                icon: "warning",
            });
            return;
        } else if (!this.state.Password) {
            Swal.fire({
                title: "กรุณาตรวจสอบข้อมูล",
                text: "กรุณากรอก Password",
                icon: "warning",
            });
            return;
        }

        CoreService.Login({ Username: this.state.Username, Password: this.state.Password })
            .then((res) => {
                let data = res.data?.Login;
                if (!data || res.errors) {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: res.errors?.[0]?.Message ?? "เกิดข้อผิดพลาดระหว่างการ Login",
                        icon: "warning",
                    });
                    return;
                }
                setLocalStorage("SessionToken", data.SessionToken);
                this.props.dispatchLogin(data);
                this.props.history.push("/home");
                // setMasterData()
                //     .then(() => {
                //         history.push("/home");
                //     });
            })
            .catch((e) => {
                console.log(e);
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: "เกิดข้อผิดพลาดระหว่างการ Login",
                    icon: "warning",
                });
            });
    };

    render() {
        return (
            <div style={{ display: "grid", position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}>
                <div
                    style={{
                        display: "table",
                        height: "calc(100vh - 72px)",
                    }}
                >
                    <Box
                        display="table-cell"
                        style={{
                            verticalAlign: "middle",
                        }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Card className="p-10" style={{ minWidth: "300px", maxWidth: "400px" }}>
                                {!this.props.isAuth ? (
                                    <form onSubmit={this.login}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            textAlign="center"
                                            p={4}
                                        >
                                            <Grid justifyContent="center" container spacing={4}>
                                                <Grid item xs={12}>
                                                    <Typography className="py-05 mb-05" align="center" variant="h3">
                                                        โครงการวิจัย
                                                    </Typography>
                                                    <Typography align="center" variant="h5">
                                                        ประเมินผลกระทบทางเศรษฐกิจ
                                                    </Typography>
                                                    <Typography align="center" variant="h5">
                                                        และทัศนคติทางสังคมต่อภัยแล้งและน้ำท่วม
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextFieldMemo
                                                        label="Username"
                                                        name="Username"
                                                        value={this.state.Username}
                                                        onChange={this.onChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextFieldMemo
                                                        label="Password"
                                                        name="Password"
                                                        type="password"
                                                        value={this.state.Password}
                                                        onChange={this.onChange}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={this.login}
                                                        type="submit"
                                                    >
                                                        Login
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <u style={{ cursor: "pointer" }}>Forgot password?</u>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>
                                ) : (
                                    this.props.history.push("/")
                                )}
                            </Card>
                        </Box>
                        <div className="copyright">
                            <h6>{fakeCopyRight.copyRight}</h6>
                            <h6 className="text-normal">{fakeCopyRight.subCopyRight}</h6>
                        </div>
                    </Box>
                </div>
            </div>
        );
    }
}

const mapDispatchtoProps = (dispatch) => ({
    dispatchHideMenu: () => dispatch(hideMenu()),
    dispatchShowMenu: () => dispatch(showMenu()),
    dispatchLogin: (data) => dispatch(login(data)),
});

const mapStatetoProps = (state) => ({ isAuth: state.isAuth });

export default connect(mapStatetoProps, mapDispatchtoProps)(LandingPage);
