import React, { useEffect } from "react";
import PageContainer from "@components/PageComponents/layouts/PageContainer";
import { Card, CardContent, Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { showMenu, hideMenu, login } from "Redux/action";
import { Description } from "@material-ui/icons";

import VersionService from "Service/_VersionService";

const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home",
    },
    {
        label: "Released Versions",
        isActive: true,
        link: "/settings/release",
    },
];

const version = VersionService.GetVersion();

const fakeData = {
    version: version.Version,
    build: version.Build,
    description: "",
    releaseNote: [
        "<div>เพิ่มหน้า Relation สำหรับตรวจสอบภาพรวมผลคะแนน</div>",
        "<div>เพิ่มหน้า Map โดยประบเปลี่ยน UI ของ Map Filter ใหม่</div>",
        "<div>เพิ่มหน้า Questionnaire Management เพื่อใช้สำหรับจัดการ Master Data ของแบบสอบถาม</div>",
    ],
};

const ReleasedVersions = (props) => {
    return (
        <PageContainer className="text-primary" padding={8} heading="Released Versions" breadcrumbs={location}>
            <Card className="p-15">
                <div className="section-header main text-primary">
                    <div className="d-flex align-items-center">
                        <Description className="mr-05" />
                        <div>Released Versions</div>
                        <h6 className="ml-10 text-normal">{`( ${fakeData.version} build: ${fakeData.build} )`}</h6>
                    </div>
                    <hr />
                </div>
                <div className="px-10 text-primary">
                    {fakeData.description ? <h4 className="text-normal mb-02 p-10">{fakeData.description}</h4> : null}
                    <h2 className="text-normal">What's new ?</h2>
                    <ul className="px-20 pt-05">
                        {fakeData.releaseNote.map((x, i) => (
                            <li key={"note-" + i} style={{ marginBottom: "10px" }}>
                                <div dangerouslySetInnerHTML={{ __html: x }}></div>
                            </li>
                        ))}
                    </ul>
                </div>
            </Card>
        </PageContainer>
    );
};

const mapDispatchtoProps = (dispatch) => ({
    dispatchHideMenu: () => dispatch(hideMenu()),
    dispatchShowMenu: () => dispatch(showMenu()),
});

export default connect(null, mapDispatchtoProps)(ReleasedVersions);
