import React, { useContext, useEffect, useState } from "react";
import AppContext from "../ContextProvider/AppContext";
import globalStyles from "@theme/GlobalCss";
import { connect } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LAYOUT_STYLES } from "@theme/constants/ThemeOptions";
import VerticalLayout from "./VerticalLayout";

const useStyles = makeStyles((theme) => ({
    circularProgressRoot: {
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffffc0",
    },
}));

const AppLayout = ({ children, showMenu, mapMode, account, dashboardMode }) => {
    const [isTemplateLoaded, setTemplateLoading] = useState(false);
    const { layoutStyle, themeType, updateThemeType } = useContext(AppContext);
    const classes = useStyles();
    globalStyles();

    useEffect(() => {
        updateThemeType(themeType);
        setTemplateLoading(true);
    }, []);

    useEffect(() => {
        setLayoutType();
    }, [layoutStyle]);

    const setLayoutType = () => {
        if (layoutStyle === LAYOUT_STYLES.FULL_WIDTH) {
            document.body.classList.remove("layout-type-boxed");
            document.body.classList.remove("layout-type-framed");
            document.body.classList.add("layout-type-fullwidth");
        } else if (layoutStyle === LAYOUT_STYLES.BOXED) {
            document.body.classList.remove("layout-type-fullwidth");
            document.body.classList.remove("layout-type-framed");
            document.body.classList.add("layout-type-boxed");
        } else if (layoutStyle === LAYOUT_STYLES.FRAMED) {
            document.body.classList.remove("layout-type-boxed");
            document.body.classList.remove("layout-type-fullwidth");
            document.body.classList.add("layout-type-framed");
        }
    };

    if (!isTemplateLoaded) {
        return (
            <Box className={classes.circularProgressRoot}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <VerticalLayout
            children={children}
            showMenu={showMenu}
            mapMode={mapMode}
            account={account}
            dashboardMode={dashboardMode}
        />
    );
};

const mapStatetoProps = (state) => ({
    showMenu: state.showMenu,
    mapMode: state.mapMode,
    account: state.account,
    dashboardMode: state.dashboardMode,
});

export default connect(mapStatetoProps)(AppLayout);
