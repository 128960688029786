import { useRef } from "react";
import Highcharts, { Pointer } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Utilities from "./Utilities";
import { Button } from "@material-ui/core";

const Chart = ({ title, data, history, color, credits, yAxis, xAxis, handleChart, handleBack, zone, unit = "ไร่" }) => {
    const charts = useRef();
    const getMinMax = (_data) => {
        return [Math.min(..._data), Math.max(..._data)];
    };

    const formatData = () => {
        if (data[0].y) {
            return data.map((row) => row.y);
        }

        return data;
    };

    const createZone = () => {
        const formatedData = formatData();

        const [min, max] = getMinMax(formatedData);

        const zoneLength = zone.length;

        const perZone = (max - min) / zoneLength;

        return zone.map((color, index) => {
            // last index
            if (index === zoneLength - 1) {
                return { color };
            }
            return { value: perZone * (index + 1), color };
        });
    };

    const options = {
        colors: color,
        credits: {
            text: credits,
        },
        legend: {
            enabled: false,
        },
        title: {
            text: title,
            align: "left",
        },
        series: [
            {
                type: "column",
                className: "cursor-pointer",
                events: {
                    click: (e) => handleChart(e, charts),
                },
                borderRadius: 5,
                data: data ?? [],
                animation: {
                    duration: 750,
                    easing: "easeOutBounce",
                },
                zones: zone ? createZone() : [],
                threshold: 0,
            },
        ],
        yAxis: [
            {
                title: { text: yAxis },
            },
        ],
        xAxis: xAxis && {
            categories: xAxis,
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point?.options?.tooltipLabel ?? this.key}</b>: ${Utilities.toCommas(this.y)} ${
                    unit ?? ""
                }`;
            },
        },
    };

    return (
        <div style={{ backgroundColor: "#fff" }}>
            {history?.length > 0 && (
                <div className="p-10">
                    <Button onClick={() => handleBack(charts)} variant="contained" color="primary">
                        Back
                    </Button>
                </div>
            )}
            <HighchartsReact immutable ref={charts} highcharts={Highcharts} options={options} />
        </div>
    );
};

export default Chart;
