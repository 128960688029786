import React, { useEffect, useState } from "react";
import PageContainer from "@components/PageComponents/layouts/PageContainer";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

import RelationService from "Service/RelationService";
import QuestionTable from "./components/QuestionTable";
import QuestionModal from "./components/QuestionModal";
import { showLoading, hideLoading } from "Redux/action";

// const data = [
//     {
//         Question: "เลี้ยงแมวมั้ย?",
//         Options: [],
//         SubQuestions: [],
//         Topic: "ทาสแมว V4",
//         Input: 1,
//         GeneralInfo: true,
//     },
//     {
//         Question: "ทำไมคนชอบเลี้ยงแมว?",
//         Options: ["เพราะแมวน่ารัก", "เพราะแมวเลี้ยงง่าย", "โดนแมวสะกดจิต"],
//         SubQuestions: [],
//         Topic: "ทาสแมว V4",
//         Input: 1,
//         GeneralInfo: false,
//     },
//     {
//         Question: "คุณมีแมวกี่ตัว?",
//         Options: [],
//         SubQuestions: [
//             {
//                 Question: "เป็นเพศผู้",
//                 Options: [],
//             },
//             {
//                 Question: "เป็นเพศเมีย",
//                 Options: [],
//             },
//         ],
//         Topic: "ทาสแมว V4",
//         Input: 1,
//         GeneralInfo: false,
//     },
//     {
//         Question: "น้องแมวดื้อมั้ย?",
//         Options: ["ไม่ดื้อ", "ดื้อนิดหน่อย", "ดื้อมากกกก", "ดื้อชิหัย"],
//         SubQuestions: [
//             {
//                 Question: "อายุเฉลี่ย",
//                 Options: ["0-2ปี", "3-5ปี", "6ปีขึ้นไป"],
//             },
//         ],
//         Topic: "ทาสแมว V4",
//         Input: 1,
//         GeneralInfo: false,
//     },
// ];

const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home",
    },
    {
        label: "Questionnaire Management",
        isActive: true,
        link: "/settings/mn-question",
    },
];

const inputList = [1, 2, 3, 4, 5];

const topicList = ["ทาสแมว V1", "ทาสแมว V2", "ทาสแมว V3", "ทาสแมว V4"];

const QuestionnaireManagement = (props) => {
    const { showLoading, hideLoading } = props;
    const [data, setData] = useState();
    const [state, setState] = useState({
        topicList: [],
        data: [],
        loadedPage: false,
        editID: 0,
        editData: {},
    });
    const [open, setOpen] = useState(false);

    const createTopic = (topicList) => {
        return topicList.map((r) => ({
            TopicID: r.TopicID,
            TopicName: `${r.TopicName} ${r.Version ? `(${r.Version})` : ""}`,
        }));
    };

    useEffect(() => {
        if (state.loadedPage && state.editID !== 0) {
            let timer = Date.now();
            let loadingTimer = null;

            showLoading();
            RelationService.getQuestionByID(state.editID).then((res) => {
                let data = res.data.GetQuestionByID;

                setState((prevState) => ({ ...prevState, editData: data }));

                if (Date.now() - timer > 3000) {
                    hideLoading();
                    setOpen(true);
                } else {
                    loadingTimer = setTimeout(() => {
                        hideLoading();
                        setOpen(true);
                    }, 1500);
                }
            });
        } else {
            setState((prevState) => ({ ...prevState, loadedPage: true }));
        }
    }, [state.editID]);

    useEffect(() => {
        showLoading();
        let timer = Date.now();
        let loadingTimer = null;
        let InitialPage = [RelationService.getQuestion(), RelationService.getTopic()];

        const init = async () => {
            await Promise.all(InitialPage)
                .then((res) => {
                    let topic = createTopic(res[1].data.GetTopic);
                    let questionList = res[0].data.GetQuestion;

                    questionList = questionList.map((q) => {
                        let topicIndex = topic.findIndex((t) => t?.TopicID === q?.TopicID);

                        if (topicIndex !== -1) {
                            return { ...q, Topic: topic[topicIndex].TopicName };
                        } else {
                            return { ...q };
                        }
                    });

                    return { topic, questionList };
                })
                .then((res) => {
                    setState((getState) => ({ ...getState, topicList: [...res.topic], data: [...res.questionList] }));
                    if (Date.now() - timer > 3000) {
                        hideLoading();
                    } else {
                        loadingTimer = setTimeout(() => {
                            hideLoading();
                        }, 1500);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    if (Date.now() - timer > 3000) {
                        hideLoading();
                    } else {
                        loadingTimer = setTimeout(() => {
                            hideLoading();
                        }, 1500);
                    }
                });
        };

        init();
    }, []);

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, editID: 0, editData: {} }));
        }, 400);
    };

    const handleEdit = async (
        e,
        { QuestionID, Question, Code, SubQuestions, Options, Input, Topic, GeneralInfo },
        closeModal
    ) => {
        e.preventDefault();

        let formatData = {
            QuestionID,
            Question,
            Code,
            Options: Options.filter((d) => d),
            SubQuestions: SubQuestions.filter((d) => d).map((d) => ({ ...d, Options: d.Options.filter((o) => o) })),
            Input,
            Topic,
            GeneralInfo,
        };

        RelationService.editQuestion(formatData)
            .then((res) => {
                console.log(res);
                if (res.errors) {
                    alert("error");
                } else {
                    alert("success");
                }
            })
            .catch((e) => {
                alert("error by catch");
                console.log(e);
            });
    };

    const handleAdd = async (e, { Question, Code, SubQuestions, Options, Input, Topic, GeneralInfo }, closeModal) => {
        e.preventDefault();
        let formatData = {
            Question,
            Code,
            Options: Options.filter((d) => d),
            SubQuestions: SubQuestions.filter((d) => d).map((d) => ({ ...d, Options: d.Options.filter((o) => o) })),
            Input,
            Topic,
            GeneralInfo,
        };

        RelationService.addQuestion(formatData)
            .then((res) => {
                if (res.errors) {
                    alert("error");
                } else {
                    alert("success");
                    closeModal();
                }
            })
            .catch((e) => {
                alert("error by catch");
                console.log(e);
            });
    };

    const handleGetEditInfo = (id) => {
        setState((prevState) => ({ ...prevState, editID: id }));
    };

    return (
        <PageContainer className="text-primary" padding={8} heading="Questionnaire Management" breadcrumbs={location}>
            <div className="text-right mb-10">
                <Button onClick={() => setOpen(true)} variant="contained" color="primary">
                    เพิ่มคำถาม
                </Button>
            </div>
            <QuestionModal
                open={open}
                data={state.editData}
                handleClose={handleClose}
                handleAdd={state.editID !== 0 ? handleEdit : handleAdd}
                inputList={inputList}
                topicList={state.topicList}
            />
            <QuestionTable data={state.data} openEdit={handleGetEditInfo} />
        </PageContainer>
    );
};

const mapStatetoProps = (state) => {
    return {
        masterData: state.masterData,
    };
};

const mapDispatchtoProps = (dispatch) => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
});

export default connect(mapStatetoProps, mapDispatchtoProps)(QuestionnaireManagement);
