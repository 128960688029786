import React, { useState, useEffect } from "react";
import RelationChart from "Util/RelationChart";
import PageContainer from "@components/PageComponents/layouts/PageContainer";
import { connect } from "react-redux";
import RelationService from "Service/RelationService";
import Swal from "sweetalert2";
import { Paper, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { data } from "./data";
import RelationFilter from "./components/RelationFilter";
import OverAll from "./components/OverAll";
import ChartAnalyst from "Util/ChartAnalyst";
import { showLoading, hideLoading } from "Redux/action";

const location = [
    {
        label: "Home",
        isActive: false,
        link: "/home",
    },
    {
        label: "ทัศนคติทางสังคม",
        isActive: true,
        link: "/relation",
    },
];

const Relation = (props) => {
    const { showLoading, hideLoading } = props;
    const [state, setState] = useState({
        data: null,
        generalInfo: null,
        selectedFilter: [],
        openDrilldown: false,
        drilldown: null,
        drilldownHeader: "",
        topic: [],
    });

    const header = [
        {
            title: "กลุ่มแบบสำรวจ",
            subTitle: "",
            lvl: 0,
        },
        {
            title: "แบบสำรวจ (input)",
            subTitle: "เครื่องมือ/หลักปฏิบัติการจัดการภัยพิบัติ",
            lvl: 1,
        },
        {
            title: "การประเมินผล (output)",
            subTitle: "หลักการจัดการภัยพิบัติที่มีประสิทธิภาพจากองค์ประกอบด้านเทคนิคและสังคม",
            lvl: 2,
        },
        {
            title: "กลุ่มการประเมินผล",
            subTitle: "",
            lvl: 3,
        },
    ];

    useEffect(() => {
        const initialPage = [RelationService.getGeneralInfo(), RelationService.getTopic()];

        const init = async () => {
            await Promise.all(initialPage)
                .then((res) => {
                    if (res[0]?.error || !res[0]?.data?.GetGeneralInfo || res[1]?.error || !res[1]?.data?.GetTopic) {
                        Swal.fire({
                            title: "เกิดข้อผิดพลาด",
                            text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                            icon: "error",
                        });
                        return;
                    } else {
                        setState((getState) => ({
                            ...getState,
                            generalInfo: res[0].data.GetGeneralInfo ?? null,
                            topic: res[1].data.GetTopic ?? [],
                        }));
                    }
                })
                .catch((e) => console.log(e));
        };

        init();
    }, []);

    useEffect(() => {
        let timer = Date.now();
        let loadingTimer = null;
        showLoading();
        RelationService.getRelation(state.selectedFilter.filter((s) => s.values?.length > 0))
            .then((res) => {
                if (res?.error || !res?.data?.GetRelation) {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                        icon: "error",
                    });
                    if (Date.now() - timer > 3000) {
                        hideLoading();
                    } else {
                        loadingTimer = setTimeout(() => {
                            hideLoading();
                        }, 1500);
                    }
                    return;
                } else {
                    setState((getState) => ({ ...getState, data: res.data.GetRelation ?? null }));
                    if (Date.now() - timer > 3000) {
                        hideLoading();
                    } else {
                        loadingTimer = setTimeout(() => {
                            hideLoading();
                        }, 1500);
                    }
                }
            })
            .catch((e) => {
                if (Date.now() - timer > 3000) {
                    hideLoading();
                } else {
                    loadingTimer = setTimeout(() => {
                        hideLoading();
                    }, 1500);
                }
                console.log(e);
            });
    }, [state.selectedFilter]);

    const handleFilter = (generalInfo) => {
        setState((getState) => ({ ...getState, selectedFilter: [...generalInfo] }));
    };

    const handleDrilldown = (data) => {
        setState((getState) => ({ ...getState, openDrilldown: true, drilldownHeader: data.label }));

        RelationService.getAnalyst(
            data.ID,
            state.selectedFilter.filter((s) => s.values?.length > 0)
        )
            .then((res) => {
                if (res?.error || !res?.data?.GetAnalystInfo) {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: "เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล",
                        icon: "error",
                    });
                    return;
                } else {
                    setState((getState) => ({ ...getState, drilldown: res.data.GetAnalystInfo.chart ?? null }));
                }
            })
            .catch((e) => console.log(e));
    };

    const handleClose = () => {
        setState((getState) => ({ ...getState, openDrilldown: false, drilldown: null, drilldownHeader: "" }));
    };

    return (
        <PageContainer className="text-primary" heading="ทัศนคติทางสังคม" breadcrumbs={location}>
            <Dialog className="relation-drilldown" open={state.openDrilldown} onClose={handleClose}>
                <DialogContent className="vw-85 vh-100">
                    <DialogTitle className="d-flex">
                        <div>{state.drilldownHeader}</div>
                        <IconButton size="small" onClick={handleClose}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContentText>
                        {!state.drilldown ? null : <ChartAnalyst data={state.drilldown} />}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <RelationFilter data={state.generalInfo} handleFilter={handleFilter} topics={state.topic} />
            <Paper className="d-flex h-100 p-10" style={{ flexDirection: "column", position: "relative" }}>
                {state.data && <OverAll data={state.data.Overall} count={state.data?.Count} />}
                <div className="d-flex mt-10 flex-fill position-relative" style={{ minHeight: "650px" }}>
                    <div className="overflow-auto d-flex flex-fill position-relative">
                        <div className="relation position-absolute w-100 h-100" style={{ top: 0, left: 0 }}>
                            <RelationChart data={state.data} header={header} handleDrilldown={handleDrilldown} />
                        </div>
                    </div>
                </div>
            </Paper>
        </PageContainer>
    );
};

const mapStatetoProps = (state) => {
    return {
        masterData: state.masterData,
    };
};

const mapDispatchtoProps = (dispatch) => ({
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
});

export default connect(mapStatetoProps, mapDispatchtoProps)(Relation);
