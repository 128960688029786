import { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Utilities from "./Utilities";

const ChartAnalyst = ({ data }) => {
    const charts = useRef();
    const { GoodBadChart, FairChart } = data;
    const height = GoodBadChart?.categories?.length < 3 ? 200 : GoodBadChart?.categories?.length * 100 ?? 600;
    let colors = ["#ef5350", "#ffcdd2", "#2196f3", "#bbdefb"];
    let level = {
        VeryGood: "มากที่สุด",
        Good: "มาก",
        Fair: "ปานกลาง",
        Bad: "น้อย",
        VeryBad: "น้อยที่สุด",
    };

    const options = {
        chart: {
            type: "bar",
            height,
        },
        title: {
            text: "",
        },
        colors,
        credits: {
            enabled: false,
        },
        legend: {
            labelFormatter: function () {
                return level[this.name];
            },
        },
        tooltip: {
            formatter: function () {
                let value = this.y < 0 ? `${-Utilities.toPrecision(this.y)}%` : `${Utilities.toPrecision(this.y)}%`;
                return `<b>${this.point?.category ?? this.key}</b><br>
                ${level[this.series.name]}: ${value}`;
            },
        },
        xAxis: [
            {
                categories: GoodBadChart.categories,
                reversed: true,
                labels: {
                    align: "left",
                    reserveSpace: true,
                    formatter: function () {
                        return `${this.pos + 1}. ${
                            this.value.length > 40 ? `${this.value.slice(0, 40)}..` : this.value
                        }`;
                    },
                },
            },
            {
                // mirror axis on right side
                opposite: true,
                reversed: false,
                linkedTo: 0,
                labels: {
                    enabled: false,
                },
            },
        ],
        yAxis: {
            min: -100,
            max: 100,
            title: {
                text: "Score",
            },
        },
        plotOptions: {
            series: {
                stacking: "normal",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y < 0 ? `${-Utilities.toPrecision(this.y)}%` : `${Utilities.toPrecision(this.y)}%`;
                    },
                },
            },
        },
        series: GoodBadChart.data,
    };

    const option = {
        chart: {
            type: "bar",
            height,
        },
        title: {
            text: "",
        },
        colors: ["#eeeeee"],
        credits: {
            enabled: false,
        },
        legend: {
            labelFormatter: function () {
                return level[this.name];
            },
        },
        tooltip: {
            formatter: function () {
                let value = this.y < 0 ? `${-Utilities.toPrecision(this.y)}%` : `${Utilities.toPrecision(this.y)}%`;
                return `<b>${this.point?.category ?? this.key}</b><br>
                ${level[this.series.name]}: ${value}`;
            },
        },
        xAxis: {
            categories: FairChart.categories,
            reversed: true,
            labels: {
                enabled: false,
            },
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: "Score",
            },
        },
        plotOptions: {
            series: {
                stacking: "normal",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y < 0 ? `${-Utilities.toPrecision(this.y)}%` : `${Utilities.toPrecision(this.y)}%`;
                    },
                },
            },
        },
        series: FairChart.data,
    };

    return (
        <div
            className="d-flex"
            style={{ backgroundColor: "#fff", height: "80vh", alignItems: "center", overflow: "auto" }}
        >
            <div style={{ width: "80%", height: height > 700 ? "100%" : "initial" }}>
                <HighchartsReact ref={charts} highcharts={Highcharts} options={options} />
            </div>
            <div style={{ width: "20%", height: height > 700 ? "100%" : "initial" }}>
                <HighchartsReact ref={charts} highcharts={Highcharts} options={option} />
            </div>
        </div>
    );
};

export default ChartAnalyst;
