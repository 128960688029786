import { useState } from "react";
import { Typography, Collapse, Card, Grid, Button, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { ExpandMore, Tune, Search, Clear, Settings, TrafficRounded } from "@material-ui/icons";

import styles from "./RelationFilter.module.css";

const createTopic = (topicList) => {
    return topicList.map((r) => ({
        TopicID: r.TopicID,
        TopicName: `${r.TopicName} ${r.Version ? `(${r.Version})` : ""}`,
    }));
};

const RelationFilter = (props) => {
    const { data, handleFilter, topics } = props;
    const [state, setState] = useState({
        showFilters: false,
        showAdvanceFilters: false,
        generalInfo: [],
    });

    const handleCheckbox = (questionID, question, value, optionName, isCommon, isTopic) => {
        setState((getState) => {
            let index = getState.generalInfo.findIndex((g) => g.questionID === questionID);

            if (index === -1) {
                return {
                    ...getState,
                    generalInfo: [
                        ...getState.generalInfo,
                        { questionID, question, isCommon, isTopic, values: [{ value, optionName }] },
                    ],
                };
            } else {
                return {
                    ...getState,
                    generalInfo: getState.generalInfo.map((g, gIndex) => {
                        let valueExist = g.values.find((v) => v.value === value);

                        if (index === gIndex) {
                            return {
                                ...g,
                                values: !valueExist
                                    ? [...g.values, { value, optionName }]
                                    : g.values.filter((v) => v.value !== value),
                            };
                        }
                        return { ...g };
                    }),
                };
            }
        });
    };

    const handleClear = () => {
        setState((getState) => ({ ...getState, generalInfo: [] }));
    };

    return (
        <>
            <Card className={"mb-10 " + styles.card}>
                <div onClick={() => setState((getState) => ({ ...getState, showFilters: !getState.showFilters }))}>
                    <Typography
                        style={{ justifyContent: "space-between" }}
                        className="d-flex"
                        variant="h5"
                        component="h2"
                    >
                        <div className="d-flex">
                            <Tune className={styles.mr2} />
                            <span>Filters</span>
                        </div>
                        <ExpandMore className={(state.showFilters ? "rotate " : "") + "notRotate "} />
                    </Typography>
                </div>
                <Collapse in={state.showFilters} timeout="auto">
                    <div className="mt-10">
                        <Grid className="p-05" container spacing={6}>
                            <Grid key={"topic"} item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <h3 style={{ fontWeight: 500 }}>แบบสอบถาม</h3>
                                <FormGroup>
                                    {createTopic(topics).map((o, oIndex) => {
                                        let value = o.TopicID;
                                        let checked =
                                            typeof state.generalInfo
                                                .find((g) => g.questionID === 0)
                                                ?.values?.find((f) => f.value === value) !== "undefined";

                                        return (
                                            <FormControlLabel
                                                key={"topic" + oIndex}
                                                control={
                                                    <Checkbox
                                                        onChange={() =>
                                                            handleCheckbox(
                                                                0,
                                                                "แบบสอบถาม",
                                                                value,
                                                                o.TopicName,
                                                                true,
                                                                true
                                                            )
                                                        }
                                                        checked={checked}
                                                        name={o.TopicName}
                                                    />
                                                }
                                                label={o.TopicName}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </Grid>
                            {data
                                ?.filter((d) => d.Type === 1)
                                .map((d, index) => {
                                    if (d.Options.length > 0 && d.Options.length < 8) {
                                        return (
                                            <Grid key={"generalInfo" + index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                <h3 style={{ fontWeight: 500 }}>{d.Question}</h3>
                                                <FormGroup>
                                                    {d.Options.map((o, oIndex) => {
                                                        let value = oIndex + 1;
                                                        let checked =
                                                            typeof state.generalInfo
                                                                .find((g) => g.questionID === d.QuestionID)
                                                                ?.values?.find((f) => f.value === value) !==
                                                            "undefined";

                                                        return (
                                                            <FormControlLabel
                                                                key={"option" + o.OptionID}
                                                                control={
                                                                    <Checkbox
                                                                        onChange={() =>
                                                                            handleCheckbox(
                                                                                d.QuestionID,
                                                                                d.Question,
                                                                                value,
                                                                                o.OptionName,
                                                                                true,
                                                                                false
                                                                            )
                                                                        }
                                                                        checked={checked}
                                                                        name={o.OptionName}
                                                                    />
                                                                }
                                                                label={o.OptionName}
                                                            />
                                                        );
                                                    })}
                                                </FormGroup>
                                            </Grid>
                                        );
                                    }
                                    return null;
                                })}
                        </Grid>
                    </div>
                    <Card className={"mb-10 " + styles.card} style={{ boxShadow: "none" }}>
                        <div
                            onClick={() =>
                                setState((getState) => ({
                                    ...getState,
                                    showAdvanceFilters: !getState.showAdvanceFilters,
                                }))
                            }
                        >
                            <Typography
                                style={{ justifyContent: "space-between" }}
                                className="d-flex"
                                variant="h5"
                                component="h2"
                            >
                                <div className="d-flex">
                                    <Settings className={styles.mr2} />
                                    <span>Advance Filters</span>
                                </div>
                                <ExpandMore className={(state.showAdvanceFilters ? "rotate " : "") + "notRotate "} />
                            </Typography>
                        </div>
                        <Collapse in={state.showAdvanceFilters} timeout="auto">
                            <div className="mt-10">
                                <Typography className="pt-10 mb-10 text-primary" variant="h3" component="h2">
                                    {topics.find((t) => t.TopicID === data?.find((d) => d.Type === 2).Topic)
                                        ?.TopicName ?? ""}
                                </Typography>
                                <Grid className="p-05" container spacing={6}>
                                    {data
                                        ?.filter((d) => d.Type === 2)
                                        .map((d, index) => {
                                            if (d.Options.length > 0 && d.Options.length < 8) {
                                                return (
                                                    <Grid
                                                        key={"generalInfo" + d.QuestionID}
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                        xl={3}
                                                    >
                                                        <h3 style={{ fontWeight: 500 }}>{d.Question}</h3>
                                                        <FormGroup>
                                                            {d.Options.map((o, oIndex) => {
                                                                let value = oIndex + 1;
                                                                let checked =
                                                                    typeof state.generalInfo
                                                                        .find((g) => g.questionID === d.QuestionID)
                                                                        ?.values?.find((f) => f.value === value) !==
                                                                    "undefined";

                                                                return (
                                                                    <FormControlLabel
                                                                        key={"option" + o.OptionID}
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={() =>
                                                                                    handleCheckbox(
                                                                                        d.QuestionID,
                                                                                        d.Question,
                                                                                        value,
                                                                                        o.OptionName,
                                                                                        false,
                                                                                        false
                                                                                    )
                                                                                }
                                                                                checked={checked}
                                                                                name={o.OptionName}
                                                                            />
                                                                        }
                                                                        label={o.OptionName}
                                                                    />
                                                                );
                                                            })}
                                                        </FormGroup>
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        })}
                                </Grid>
                                <Typography className="pt-10 mb-10 text-primary" variant="h3" component="h2">
                                    {topics.find((t) => t.TopicID === data?.find((d) => d.Type === 3).Topic)
                                        ?.TopicName ?? ""}
                                </Typography>
                                <Grid className="p-05" container spacing={6}>
                                    {data
                                        ?.filter((d) => d.Type === 3)
                                        .map((d, index) => {
                                            if (d.Options.length > 0 && d.Options.length < 8) {
                                                return (
                                                    <Grid
                                                        key={"generalInfo" + d.QuestionID}
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                        xl={3}
                                                    >
                                                        <h3 style={{ fontWeight: 500 }}>{d.Question}</h3>
                                                        <FormGroup>
                                                            {d.Options.map((o, oIndex) => {
                                                                let value = oIndex + 1;
                                                                let checked =
                                                                    typeof state.generalInfo
                                                                        .find((g) => g.questionID === d.QuestionID)
                                                                        ?.values?.find((f) => f.value === value) !==
                                                                    "undefined";

                                                                return (
                                                                    <FormControlLabel
                                                                        key={"option" + o.OptionID}
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={() =>
                                                                                    handleCheckbox(
                                                                                        d.QuestionID,
                                                                                        d.Question,
                                                                                        value,
                                                                                        o.OptionName,
                                                                                        false,
                                                                                        false
                                                                                    )
                                                                                }
                                                                                checked={checked}
                                                                                name={o.OptionName}
                                                                            />
                                                                        }
                                                                        label={o.OptionName}
                                                                    />
                                                                );
                                                            })}
                                                        </FormGroup>
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        })}
                                </Grid>
                            </div>
                        </Collapse>
                    </Card>
                    <div className="d-flex mt-10">
                        <Button
                            className="ml-auto"
                            variant="contained"
                            color="primary"
                            onClick={() => handleFilter(state.generalInfo)}
                        >
                            <Search className={styles.iconButton} />
                            ค้นหา
                        </Button>
                        <Button className="ml-10" variant="contained" onClick={handleClear}>
                            <Clear className={styles.iconButton} />
                            ยกเลิก
                        </Button>
                    </div>
                </Collapse>
            </Card>
        </>
    );
};

export default RelationFilter;
